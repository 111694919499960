import { render, staticRenderFns } from "./AppAside.vue?vue&type=template&id=08b57169&scoped=true&"
import script from "./AppAside.vue?vue&type=script&lang=js&"
export * from "./AppAside.vue?vue&type=script&lang=js&"
import style0 from "./AppAside.vue?vue&type=style&index=0&id=08b57169&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08b57169",
  null
  
)

export default component.exports