<template>
  <app-page title="Settings" :subtitle="`Configure and update settings for ${companyState.name}`" icon="cogs">
    <template v-slot:header v-if="termsUrl">
      <b-button v-if="isSme || shouldSignDbTermsAddendum" @click="openPDF(termsUrl)" variant="primary">
        <font-awesome-icon :icon="['fas', 'file-lines']" class="mr-2" />Terms of Business
      </b-button>
      <b-button @click="openPDF(subprocessorListUrl)" variant="primary">
        <font-awesome-icon :icon="['fas', 'user-shield']" class="mr-2" />Subprocessor List
      </b-button>
      <b-button @click="openPDF(emiTermsUrl)" variant="primary">
        <font-awesome-icon :icon="['fas', 'money-bill-transfer']" class="mr-2" />EMI Terms
      </b-button>
      <b-button @click="openPDF(emiInfoUrl)" variant="secondary">
        <font-awesome-icon :icon="['fas', 'money-bill-transfer']" class="mr-2" />EMI Duty
      </b-button>
    </template>

    <sme-alert level="info" class="mb-3">
      We have updated our subprocessor list. Please review the latest version to stay informed about our processing
      partners.
    </sme-alert>

    <sme-alert level="warning" class="mb-3">
      Changing these settings may affect how much employees can access and when deductions will occur. If in any doubt,
      contact support.
    </sme-alert>

    <ValidationObserver v-slot="v" tag="div">
      <b-form @submit.prevent="v.handleSubmit(onSave)">
        <table class="table" id="company-settings-table">
          <tbody>
            <tr>
              <td>
                <div class="cell-title">
                  <h4>Trading Name</h4>
                </div>
                <p>Preferred name to be used in communication with employees, if different from registered name.</p>
              </td>
              <td v-if="editable">
                <app-input
                  v-model="companyState.properties.sme_properties.sme_trading_name"
                  type="text"
                  name="trading name"
                  rules="required"
                  :placeholder="prettifyCompanyName(companyState.name)"
                  class="cell-value"
                ></app-input>
              </td>
              <td class="cell-value" v-if="!editable">
                {{ companyState.properties.sme_properties.sme_trading_name }}
              </td>
            </tr>

            <tr v-if="isSelfFunded">
              <td>
                <div class="cell-title">
                  <h4>Monthly Streamed Wages Limit</h4>
                </div>
                <p>Monthly maximum transfer cap (if available) for all employees.</p>
              </td>
              <td v-if="editable">
                <app-input
                  v-model="monthlyTransferLimit"
                  type="number"
                  step="1"
                  :formatter="formatToTwoDecimals"
                  lazy-formatter
                  name="company monthly transfer limit"
                  rules="required"
                  placeholder="Enter company monthly transfer limit"
                  prepend="£"
                  class="cell-value"
                ></app-input>
              </td>
              <td class="cell-value" v-if="!editable">
                {{ toCurrency(companyState.company_monthly_transfer_limit) }}
              </td>
            </tr>

            <tr v-if="isSme">
              <td>
                <div class="cell-title">
                  <h4>Simple Deductions</h4>
                </div>
                <p>
                  By default, we reclaim all streams from the pay day of the pay period in which the stream was made. If
                  you'd rather deduct all amounts taken before a payday, even if it falls in the next pay period, check
                  this box.
                </p>
              </td>
              <td>
                <app-input
                  v-model="simpleDeductionsEnabled"
                  type="checkbox-single"
                  name="maximum reclaim"
                  class="cell-value"
                  :disabled="!editable"
                ></app-input>
              </td>
            </tr>

            <tr v-if="isEnableWhatsApp">
              <td>
                <div class="cell-title">
                  <h4>WhatsApp Opt-In</h4>
                </div>
                <p>
                  Turn on WhatsApp invites, and we'll send a one-time WhatsApp (or an SMS if they do not have WhatsApp)
                  message to any new employees going forward inviting them to enrol, helping to ensure maximum
                  visibility within your business.
                </p>
                <p>
                  To enable this, you'll need to accept an amendment to our Agreement, which can be viewed
                  <a :href="whatsappContentsUrl && `/#${whatsappContentsUrl.split('#')[1]}`" target="_blank">here</a>,
                  and ensure you have your employees' consent. Your employees can opt out of WhatsApp and/or SMS
                  communications at any time.
                </p>
              </td>
              <td>
                <app-input
                  v-model="whatsappOptInEnabled"
                  type="checkbox-single"
                  name="whatsapp opt in"
                  class="cell-value"
                  :disabled="!editable"
                ></app-input>
              </td>
            </tr>

            <tr>
              <td>
                <div class="cell-title">
                  <h4>Payroll Return Payment Banking Details</h4>
                </div>
                <p>
                  Enter the banking details for the account where we should send any return payments to your company.
                </p>
              </td>
              <td>
                <app-input
                  v-model="companySortCode"
                  type="text"
                  name="Return payment sort code"
                  class="cell-value"
                  :rules="{ regex: getBankingInfo.sortCode.regex }"
                  :placeholder="companyState.properties.beneficiary_sort_code || getBankingInfo.sortCode.placeholder"
                  :plaintext="!editable"
                  row
                >
                  <template #label>
                    {{ getBankingInfo.sortCode.label }}
                    <small v-if="editable">{{ getBankingInfo.sortCode.description }}</small>
                  </template>
                </app-input>

                <app-input
                  v-model="companyAccountNumber"
                  type="text"
                  name="Return payment account number"
                  class="cell-value"
                  :rules="{ regex: getBankingInfo.accountNumber.regex }"
                  :placeholder="
                    companyState.properties.beneficiary_account_number || getBankingInfo.accountNumber.placeholder
                  "
                  :plaintext="!editable"
                  row
                >
                  <template #label>
                    Account Number
                    <small v-if="editable">{{ getBankingInfo.accountNumber.description }}</small>
                  </template>
                </app-input>
              </td>
            </tr>

            <tr v-if="canSalarySignOff">
              <td>
                <div class="cell-title">
                  <h4>Salary Sign-Off Emails</h4>
                </div>
                <p>
                  We can send a weekly email to your company admins, requesting confirmation that our records of your
                  salaried employees are correct. This allows them to continue streaming their wages as normal.
                </p>
                <p>
                  Check this box if you'd like to receive these emails. Note that having them disabled may put you at
                  risk of overpayments if you don't let us know immediately when someone leaves your company.
                </p>
              </td>
              <td>
                <app-input
                  v-model="receiveSalarySignOffEmails"
                  type="checkbox-single"
                  name="salary sign-off"
                  class="cell-value"
                  :disabled="!editable"
                ></app-input>
              </td>
            </tr>
          </tbody>
        </table>

        <page-sub-footer>
          <template #before>
            <sme-alert v-if="saveError" level="danger">{{ saveError }}</sme-alert>
          </template>
          <b-button type="submit" variant="primary" :disabled="v.invalid || saving">
            <b-spinner v-if="saving" class="mr-2" small />
            {{ saving ? 'Saving...' : editable ? 'Save' : 'Edit' }}
          </b-button>
          <b-button v-if="editable" variant="outline-primary" :disabled="saving" @click.prevent="handleCancel">
            Cancel
          </b-button>
        </page-sub-footer>
      </b-form>
    </ValidationObserver>
  </app-page>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import defaultsDeep from 'lodash/defaultsDeep';
import { ValidationObserver } from 'vee-validate';
import ApiClient from '@/ApiClient';
import { getBankingInfo } from '@/Banking';
import tracker from '@/Tracker';
import AppInput from '@/components/AppInput.vue';
import AppPage from '@/components/AppPage';
import PageSubFooter from '@/components/PageSubFooter.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import useFeatureFlags from '@/composables/useFeatureFlags';
import State from '@/state/State';
import { toCurrency } from '@/utils';
import {
  DB_SALARY_SERVICE_ADDENDUM,
  DB_TERMS_OF_BUSINESS_AMENDMENT,
  HEALTHCARE_TERMS_OF_BUSINESS_AMENDMENT,
} from '@/utils/agreements';
import { currencySymbolFromCode } from '@/utils/common';

export default {
  name: 'CompanySettings',
  components: { AppPage, ValidationObserver, AppInput, PageSubFooter, SmeAlert },
  data() {
    return {
      companyState: this.getInitialCompanyState(),
      editable: false,
      saving: false,
      saveError: undefined,
      whatsappOptInEnabled: this.getInitialWhatsappOnboardingOptIn(),
      whatsappContentsUrl: undefined,
      whatsappAgreementId: undefined,
      companySortCode: undefined,
      companyAccountNumber: undefined,
      fieldPermissions: [],
      receiveSalarySignOffEmails: undefined,
      termsUrl: '',
      subprocessorListUrl: 'https://legal.wagestream.co.uk/wagestream_subprocessor_list',
      emiTermsUrl: 'https://legal.wagestream.co.uk/Modulr_Introduced%20Client%20Agreement_190904.pdf',
      emiInfoUrl: 'https://legal.wagestream.co.uk/Consumer+Duty+Explanatory+Note.pdf',
    };
  },
  async mounted() {
    const result = await ApiClient.smeGetWhatsAppOptIn();
    this.whatsappContentsUrl = result.data?.data?.contents_url;
    this.whatsappAgreementId = result.data?.data?.agreement_id;
    this.companySortCode = this.companyState.properties.beneficiary_sort_code;
    this.companyAccountNumber = this.companyState.properties.beneficiary_account_number;
    this.receiveSalarySignOffEmails = !this.companyState.properties.salary_sign_off_opted_out_at;
    let termsInfo;
    if (this.companyState?.properties?.[DB_SALARY_SERVICE_ADDENDUM.companyProperty]) {
      termsInfo = await ApiClient.getAgreement(DB_SALARY_SERVICE_ADDENDUM.id);
    } else if (this.companyState?.properties?.[DB_TERMS_OF_BUSINESS_AMENDMENT.companyProperty]) {
      termsInfo = await ApiClient.getAgreement(DB_TERMS_OF_BUSINESS_AMENDMENT.id);
    } else if (this.companyState?.properties?.[HEALTHCARE_TERMS_OF_BUSINESS_AMENDMENT.companyProperty]) {
      termsInfo = await ApiClient.getAgreement(HEALTHCARE_TERMS_OF_BUSINESS_AMENDMENT.id);
    } else {
      termsInfo = await ApiClient.smeGetTerms();
    }
    this.termsUrl = termsInfo?.contents_url;
  },
  setup() {
    const { isEnableWhatsApp, isSelfFunded, canSalarySignOff, isSme } = useFeatureFlags();
    return {
      isEnableWhatsApp,
      isSelfFunded,
      canSalarySignOff,
      isSme,
    };
  },
  computed: {
    getBankingInfo: () => getBankingInfo(),
    monthlyTransferLimit: {
      get() {
        return this.companyState.company_monthly_transfer_limit
          ? this.companyState.company_monthly_transfer_limit / 100
          : undefined;
      },
      set(value) {
        if (value) {
          this.companyState.company_monthly_transfer_limit = value * 100;
        } else {
          this.companyState.company_monthly_transfer_limit = undefined;
        }
      },
    },
    simpleDeductionsEnabled: {
      get() {
        return this.companyState.properties.maximum_reclaim === -1;
      },
      set(value) {
        this.companyState.properties.maximum_reclaim = value ? -1 : undefined;
      },
    },
  },

  methods: {
    toCurrency,
    currencySymbolFromCode,
    getInitialCompanyState() {
      return defaultsDeep(cloneDeep(State.state.company), {
        properties: {
          maximum_reclaim: State.state.company.properties.maximum_reclaim === -1 || undefined,
          sme_properties: {
            sme_trading_name: State.state.company.name,
          },
        },
      });
    },
    getInitialWhatsappOnboardingOptIn() {
      return State.state.company.properties.sme_properties?.whatsapp_onboarding_opt_in || false;
    },
    setInitialState() {
      this.companyState = this.getInitialCompanyState();
      this.whatsappOptInEnabled = this.getInitialWhatsappOnboardingOptIn();
    },
    prettifyCompanyName(name) {
      let lowerName = name.toLowerCase();
      if (lowerName.endsWith('ltd')) {
        lowerName = lowerName.slice(0, -3);
      }
      return lowerName.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
    },
    handleCancel() {
      this.setInitialState();

      this.editable = false;
    },
    formatToTwoDecimals(value) {
      if (Number(value)) {
        return Number(Number(value).toFixed(2));
      }
    },
    async onSave() {
      if (!this.editable) {
        this.editable = true;

        return;
      }
      const company = this.companyState;
      const propsToUpdate = {
        company_id: company.company_id,
        company_monthly_transfer_limit: company.company_monthly_transfer_limit,
        properties: company.properties,
      };

      this.saveError = undefined;
      this.saving = true;

      try {
        await ApiClient.smeUpdateCompanyProperties(company.company_id, propsToUpdate);
        await this.optInWhatsapp();
        await this.toggleSalarySignOffEmails();
        await this.setCompanyBankDetails();
        await ApiClient.getUser();

        this.setInitialState();

        this.editable = false;

        this.$appToast(`${company.name}'s details have been changed`, {
          title: 'Successfully updated company',
          variant: 'success',
        });
        tracker.trackEngagement('company_settings_changed');
      } catch (error) {
        this.saveError = error.message;
      } finally {
        this.saving = false;
      }
    },
    async optInWhatsapp() {
      const optIn = this.whatsappOptInEnabled || false;
      const agreementId = this.whatsappAgreementId;

      await ApiClient.smePostWhatsAppOptIn(optIn, State.state.user.user_id, agreementId);
    },
    async setCompanyBankDetails() {
      if (this.companySortCode && this.companyAccountNumber) {
        const payload = { sort_code: this.companySortCode, account_number: this.companyAccountNumber };
        await ApiClient.setCompanyReturnPaymentBankDetails(payload);
      }
    },
    async toggleSalarySignOffEmails() {
      const optIn = this.receiveSalarySignOffEmails || false;
      await ApiClient.toggleSalarySignOffEmails(State.state.company.company_id, optIn);
    },
    openPDF(url) {
      window.open(url, '_blank');
    },
  },
};
</script>

<style scoped lang="scss">
.edit-save {
  padding-top: 0.75rem;
  padding-bottom: 1rem;
  border-top: 1px solid var(--palette-color-default-lighten-90);
  justify-content: flex-end;

  button {
    align-self: center;
  }
}

.cell-value {
  width: 20rem;
}

.bank-details {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.row-description {
  margin: 0 0.75rem;
}
</style>
