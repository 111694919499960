import { stringify } from 'querystring';
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import base64 from 'base64-js';
import Cookies from 'js-cookie';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import * as moment from 'moment/moment';
import { UAParser } from 'ua-parser-js';
import { Userpilot } from 'userpilot';
import Config from '@/Config';
import Router from '@/Router';
import store from '@/state';
import IntegrationsState from '@/state/IntegrationsState';
import State from '@/state/State';
import { resetStates as resetComposableStates } from '@/state/composables';
import { DEFAULT_PROXIMATE_DISTANCE_IN_METERS } from '@/utils/flexible-work';
import { getPartnership } from '@/utils/partnership';
import { vm } from '@/vm';
import { setDateTimeToUTC } from '@/pages/talent-pool/utils/datetime';

const localStorageKeyAccessToken = 'accessToken';
const localStorageKeyRefreshToken = 'refreshToken';
// NOTE: for testing bring this down to 2 seconds and set the API JWT expiry to 30 seconds.
const tokenExpiryMarginSeconds = 30;

axios.defaults.responseType = 'json';
axios.defaults.headers.common['X-Client-Partnership'] = getPartnership();
let accessToken = localStorage.getItem(localStorageKeyAccessToken);
const uaParserResult = new UAParser().getResult();
if (accessToken) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
}

function decodeJWT(token) {
  //NOTE: We're only interested in the payload
  //	    the signature will be verified by the API on each request
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch {
    console.error('Bad token: ' + token);
    return null;
  }
}

function isJWTDueToExpireBeforeRequest(token) {
  if (!token) {
    return true;
  }

  const jwt = decodeJWT(token);
  if (!jwt) {
    return true;
  }
  const expirySeconds = jwt.exp;
  if (expirySeconds) {
    const nowSeconds = Math.floor(Date.now() / 1000);
    return nowSeconds >= expirySeconds - tokenExpiryMarginSeconds;
  }

  // No expiry - switch to true to "force" everyone over to new tokens
  return false;
}

function getCompanyIdFromJWT(token) {
  if (!token) {
    return;
  }
  const jwt = decodeJWT(token);
  return (jwt.user_claims || {}).cid;
}

function getJWTFromAxiosRequest(req) {
  //WARNING: axios doesn't define headers under a single 'headers' object
  const authHeader = (((req.headers || {}).common || {}).Authorization || '').split('Bearer ');
  if (Array.isArray(authHeader) && authHeader.length > 0) {
    return authHeader[1];
  }
}

function appendRequestToPostRefreshQueue(req) {
  // Let's attempt to get a new access token as current one has expired
  const refreshToken = getRefreshToken();
  if (!refreshToken || isJWTDueToExpireBeforeRequest(refreshToken)) {
    // Nothing else we can do here
    logout('sessionExpired');
    return false;
  }

  if (!refreshInProgressPromise) {
    // No queue so create new one

    refreshInProgressPromise = new Promise(function (resolve, reject) {
      const refreshData = { requires_admin: true };

      try {
        const companyId = getCompanyIdFromJWT(accessToken);
        if (companyId) {
          refreshData['company_id'] = companyId;
        }
      } catch {
        // Do nothing. The above can throw an exception but unclear
        // under what circumstances. Do not need this anyway.
      }

      function refreshSuccess(response) {
        if (response.data && response.data['is_valid'] && response.data['access_token']) {
          // NOTE: Intentionally no reissuance of a refresh token with only a refresh token
          const accessToken = response.data['access_token'];

          setToken(accessToken);
          axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;

          refreshInProgressPromise = null;

          //Update token on queued request
          req.headers['Authorization'] = `Bearer ${accessToken}`;
          resolve(req);
        } else {
          // not valid or no access token so reject
          reject();
        }
      }

      function refreshError() {
        // not valid or no access token so logout
        logout('sessionExpired');
        throw new axios.Cancel('Session expired');
      }

      // Standardise on axios  for sending refresh requests as most requests use this
      // Otherwise we have two separate paths for refreshing one through fetch one through axios
      axios
        .post(`${Config.apiBaseAuthUrl}/auth/refresh`, JSON.stringify(refreshData), {
          headers: {
            Authorization: `Bearer ${refreshToken}`,
            'Content-Type': 'application/json',
          },
        })
        .then(refreshSuccess)
        .catch(refreshError);
    });

    return refreshInProgressPromise;
  }

  // Refresh in progress so queue request in parallel
  return refreshInProgressPromise.then(() => {
    accessToken = window.localStorage.getItem(localStorageKeyAccessToken);
    //Update token on queued request
    req.headers['Authorization'] = `Bearer ${accessToken}`;
    return req;
  });
}

// Promise used during both axios and fetch requests
let refreshInProgressPromise = null;

axios.interceptors.request.use(
  function (req) {
    // Avoid intercepting auth routes, otherwise infinite loop
    // Logout will invalidate refresh token so no need to worry if it's expired already
    const ignore = ['/login', '/logout', '/refresh', '/register'];

    if (ignore.filter(e => req.url.indexOf(e) >= 0).length > 0) {
      return req;
    }

    //NOTE: Important to check token attached to axios request not just the default token
    const jwt = getJWTFromAxiosRequest(req) || getToken();

    if (!jwt) {
      return req;
    }

    if (!isJWTDueToExpireBeforeRequest(jwt)) {
      // all fine, pass through
      return req;
    }

    const queuedRequest = appendRequestToPostRefreshQueue(req);

    if (!queuedRequest) {
      throw new axios.Cancel('Session expired');
    }

    return queuedRequest;
  },
  function (error) {
    //Pass any errors through
    return Promise.reject(error);
  },
);

export const getToken = () => {
  return window.localStorage.getItem(localStorageKeyAccessToken);
};

export function setToken(token) {
  window.localStorage.setItem(localStorageKeyAccessToken, token);
}

export const getRefreshToken = () => {
  return window.localStorage.getItem(localStorageKeyRefreshToken);
};

export function setRefreshToken(refreshToken) {
  window.localStorage.setItem(localStorageKeyRefreshToken, refreshToken);
}

export const isLoggedIn = () => {
  return Boolean(getToken());
};

export const logout = async reason => {
  const success = await ApiClient.logout();

  if ((!success || success.status != 205) && reason != 'sessionExpired') {
    console.error('Error logging out, but clearing tokens anyway.');
  }

  window.localStorage.removeItem(localStorageKeyAccessToken);
  window.localStorage.removeItem(localStorageKeyRefreshToken);

  State.logout();
  IntegrationsState.logout();
  store.commit('filters/logout');
  resetComposableStates();

  delete axios.defaults.headers.common['Authorization'];
  // eslint-disable-next-line no-unused-vars
  vm.then(v => {
    // Intercom
    if (v.$intercom) {
      v.$intercom.shutdown();
      v.$intercom.boot({ hide_default_launcher: true });
    }

    // Sentry
    Sentry.configureScope(function (scope) {
      scope.setUser({});
    });
  });

  const params = {};

  if (reason === 'sessionExpired') {
    params.sessionExpired = true;
  }

  Router.push({ name: 'login', params });
};

const setIdleLogoutTimer = () => {
  let time;

  const onIdle = () => {
    clearTimeout(time);
    document.onmousemove = null;
    document.onkeypress = null;
    logout('sessionExpired');
  };

  const resetTimer = () => {
    clearTimeout(time);
    time = setTimeout(onIdle, State.state.idleLogoutTimeInSeconds * 1000);
  };

  window.onload = resetTimer;
  document.onmousemove = resetTimer;
  document.onkeypress = resetTimer;
};

export const handleLogin = async (accessToken, refreshToken) => {
  setToken(accessToken);
  setRefreshToken(refreshToken);

  axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  const user = await ApiClient.getUser();

  if (State.state.idleLogoutTimeInSeconds) {
    setIdleLogoutTimer();
  }

  // eslint-disable-next-line no-unused-vars
  vm.then(v => {
    setTimeout(() => {
      if (!State.state?.company) {
        return;
      }
      // Intercom
      v.$intercom?.boot({
        user_id: State.state.user.user_id,
        user_hash: State.state.credentials.intercom_user_hash,
        name: State.state.user.username,
        email: State.state.user.email,
        company_id: State.state.company.company_id,
        company: {
          name: State.state.company.name,
          id: State.state.company.company_id,
          self_funded: State.state.company.properties.funding_self,
          size: 250,
        },
        self_funded: State.state.company.properties.funding_self,
        hide_default_launcher: true,
      });

      // Userpilot
      Userpilot.identify(State.state.user.user_id, {
        name: State.state.user.username,
        email: State.state.user.email,
        created_at: new Date(State.state.user.created_at).getTime(),
        company: {
          name: State.state.company.name,
          id: State.state.company.company_id,
          created_at: new Date(State.state.company.created_at).getTime(),
        },
      });

      // Sentry
      Sentry.configureScope(function (scope) {
        scope.setUser({
          email: State.state.user.email,
          username: State.state.user.username,
          id: State.state.user.user_id,
          company_name: State.state.company.name,
          company_id: State.state.company.company_id,
        });
      });

      // Heap - if not impersonating
      // if (!State.state.claims.imp) {
      //   window.heap.load('2664699295');
      //   window.heap.identify(State.state.user.user_id);
      //   window.heap.addUserProperties({
      //     email: State.state.user.email,
      //     username: State.state.user.username,
      //     id: State.state.user.user_id,
      //     company_name: State.state.company.name,
      //     company_id: State.state.company.company_id,
      //   });
      // }

      // Front
      // console.log('FrontChat init (ApiClient): '+State.state.user.email+' '+State.state.user.username+' '+State.state.credentials.front_user_hash+' '+State.state.company.name+' '+State.state.company.company_id);
      window.FrontChat?.('init', {
        chatId: 'eff9a91d417917f4d1378bcd9c9443e8',
        useDefaultLauncher: true,
        email: State.state.user.email,
        name: State.state.company.name,
        userHash: State.state.credentials.front_user_hash,
        customFields: {
          company_name: State.state.company.name,
          company_id: State.state.company.company_id,
          user_name: State.state.user.username,
        },
      });

      const partnership = 'wagestream';

      // Elevio
      const groups = [State.state.company.name, State.state.company.partner_state, location.hostname, partnership];

      if (State.state.claims.a) {
        groups.push('Admins');
      }
      if (State.state.claims.m) {
        groups.push('Managers');
      }
      if (State.state.claims.is) {
        groups.push('Infostream Managers');
      }
    }, 2500);
  });
  return user;
};

class ApiError extends Error {
  constructor(message) {
    super(message);
    this.type = 'ApiError';
  }
}

const handleError = error => {
  const baseContext = {
    page: window.location.href,
    time: new Date().toISOString(),
    companyId: State?.state?.company?.company_id,
    userId: State?.state?.user?.user_id,
    environment: process.env.NODE_ENV,
    baseUrl: Config.apiBaseUrl,
  };

  const formatContext = context =>
    Object.entries(context)
      .map(([key, value]) => {
        if (typeof value === 'object' && value !== null) {
          value = JSON.stringify(value, null, 2);
        }
        return `${key}: ${value}`;
      })
      .join('\n');

  let apiError;
  let apiContext;

  if (error?.response) {
    apiContext = {
      ...baseContext,
      message: error?.response?.data?.message || error?.response?.data || error?.message || 'Api Error',
      status: error?.response?.status,
      data: error?.response?.data,
      headers: error?.response?.headers,
      url: error?.config?.url,
    };

    const stringContext = formatContext(apiContext);

    apiError = new ApiError(`Error in ApiClient:\n${stringContext}`);
  } else {
    apiContext = {
      ...baseContext,
      message: error?.message || 'Api Error',
      status: 500,
      data: 'No response data',
      headers: 'No response headers',
      url: error?.config?.url,
    };

    const stringContext = formatContext(apiContext);

    apiError = new ApiError(`Error in ApiClient:\n${stringContext}`);
  }

  // send a rich error to sentry
  Sentry.withScope(scope => {
    scope.setExtra('errorDetails', apiContext);
    Sentry.captureException(apiError);
  });

  // return basic error for the portal to render
  return Promise.reject(
    new ApiError(
      `Request failed: ${error?.response?.data?.message || error?.response?.data || error?.message || 'Api Error'}`,
    ),
  );
};

// If the datetime is returned as UTC from the api, we ensure it has the timezone information correctly
const readDatetimeAsUTC = datetime => {
  return datetime ? datetime + 'Z' : '';
};

export const portalEnvs = {
  'dashboard.wagestream.com': 'prod',
  'sme.wagestream.com': 'prod',
  'partners.wagestream.co.uk': 'prod',
  'partners.wagestream.com': 'prod',
  'portal.wagestream.com': 'prod',
  'raise.wagestream.com': 'prod',
  'portal.staging.wagestream.xyz': 'staging',
  'portal.myviewpn.com': 'prod',
  'portal.staging.myviewpn.com': 'staging',
  'portal.financialwellbeing.webitrent.com': 'prod',
  'portal.financialwellbeing.people-first.com': 'prod',
  'sme.wagestream.us': 'prod',
  'sme.staging.wagestream.us': 'prod',
  'portal.wagestream.us': 'prod',
  'portal.staging.wagestream.us': 'prod',
};

const ApiClient = {
  async login(payload) {
    const formData = new FormData();
    if (payload.username) {
      formData.append('username', payload.username);
    }
    if (payload.password) {
      formData.append('password', payload.password);
    }
    if (payload['2fa_pin']) {
      formData.append('2fa_pin', payload['2fa_pin']);
    }
    if (payload['2fa_id']) {
      formData.append('2fa_id', payload['2fa_id']);
    }

    if (payload['company_id']) {
      formData.append('company_id', payload['company_id']);
    }

    formData.append('requires_admin', true);
    payload = { ...payload, requires_admin: true };

    try {
      const response = await axios.post(`${Config.apiBaseAuthUrl}/auth/login`, formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'X-Device-Id': payload.deviceId,
          'X-Device-Platform': 'BROWSER',
          'X-Device-Model': uaParserResult.browser.name || 'unknown',
          'X-Device-SystemVersion': uaParserResult.browser.version || 'unknown',
          'X-Application-Id': 'com.wagestream.sme-portal',
        },
      });
      const data = response?.data;

      if (!data) {
        throw 'Invalid response';
      }

      if (data.is_valid) {
        return handleLogin(data.access_token, data.refresh_token);
      }
      if (data.requires_company) {
        throw {
          requires_company: true,
          companies: data.companies,
          message: 'Company ID required',
        };
      }
      if (data.mfa_id) {
        throw {
          requires_mfa: true,
          mfa_id: data.mfa_id,
          message: 'MFA pin required',
        };
      }
      throw {
        message: payload['2fa_id'] ? 'Invalid PIN' : 'Invalid credentials',
      };
    } catch (error) {
      const data = error?.response?.data;

      if (data?.requires_elevation) {
        const deviceId = Cookies.get('wagestream_device_id');
        const elevation = await this.getElevation(data.elevation_id, deviceId);

        if (elevation.attempts_remaining === 0) {
          throw {
            message: 'Number of 2FA attempts exceeded. Please try to log in again.',
          };
        }

        throw {
          requires_elevation: true,
          elevation_id: elevation.elevation_id,
          elevation_strategy: elevation.elevation_strategy,
          elevation_attempts_remaining: elevation.attempts_remaining,
        };
      }

      throw error;
    }
  },
  async logout() {
    const refreshToken = getRefreshToken();
    return axios
      .post(`${Config.apiBaseAuthUrl}/auth/logout`, JSON.stringify('{}'), {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
          'Content-Type': 'application/json',
        },
      })
      .catch(() => {
        return false;
      });
  },
  forgotPassword(email) {
    return axios
      .put(`${Config.apiBaseAuthUrl}/auth/forgot`, { email: email })
      .then(response => response.data, handleError);
  },
  resetPassword(password, reset_id, reset_token) {
    return axios.put(`${Config.apiBaseAuthUrl}/auth/forgot`, {
      password: password,
      reset_id: reset_id,
      token: reset_token,
    });
  },

  createChallenge2fa(userId) {
    return axios
      .post(`${Config.apiBaseAuthUrl}/auth/challenge-2fa`, { user_id: userId })
      .then(response => response.data, handleError);
  },
  getElevation(elevationId, deviceId) {
    return axios
      .get(`${Config.apiBaseAuthUrl}/auth/elevations/${elevationId}`, {
        headers: {
          'X-Device-Id': deviceId,
        },
      })
      .then(response => response.data);
  },
  async checkElevation(elevationId, pin, deviceId) {
    const { data } = await axios.post(
      `${Config.apiBaseAuthUrl}/auth/elevations/${elevationId}`,
      new URLSearchParams({
        response: pin,
      }),
      {
        headers: {
          'X-Device-Id': deviceId,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      },
    );

    if (data.success) {
      return { success: true };
    }

    return { success: false };
  },
  checkChallenge2fa(mfaId, pin) {
    return axios
      .post(`${Config.apiBaseAuthUrl}/auth/verify-2fa`, { mfa_id: mfaId, mfa_pin: pin })
      .then(response => response.data, handleError);
  },

  async startOauth2(provider, company_id, next, callback) {
    const url = `${Config.apiBaseUrl}/sme/authorize/${provider}/${company_id}?next=${encodeURIComponent(
      next,
    )}&callback=${encodeURIComponent(callback)}`;
    const resp = await axios.get(url);
    return resp.data;
  },

  async completeOauth2(provider, company_id, code, state, callback) {
    const url = `${Config.apiBaseUrl}/sme/authorize/${provider}/${company_id}?code=${encodeURIComponent(
      code,
    )}&state=${encodeURIComponent(state)}&callback=${encodeURIComponent(callback)}`;
    const resp = await axios.get(url);
    return resp.data;
  },

  async getXeroAuthUrl(callback_url, state) {
    const url = `${Config.apiBaseUrl}/sme/xero/authorize?callback_url=${callback_url}&state=${state}`;
    const resp = await axios.get(url);
    return resp.data;
  },

  async getXeroConfiguration(companyId) {
    const url = `${Config.apiBaseUrl}/sme/xero/configuration/${companyId}`;
    const resp = await axios.get(url);
    return resp.data;
  },

  async putXeroUnlink(companyId) {
    const url = `${Config.apiBaseUrl}/sme/xero/unlink/${companyId}`;
    const resp = await axios.put(url);
    return resp.data;
  },

  async postXeroRegistration(payload) {
    const url = `${Config.apiBaseUrl}/sme/xero/register`;
    const resp = await axios.post(url, payload);
    if (resp.data.auth) {
      setToken(resp.data.auth.access_token);
      setRefreshToken(resp.data.auth.refresh_token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${resp.data.auth.access_token}`;
    }
    return resp.data;
  },

  async configureIntegration(provider, company_id, payload) {
    const url = `${Config.apiBaseUrl}/sme/configure/${provider}/${company_id}`;
    const resp = await axios.post(url, payload);
    return resp.data;
  },

  async getUser() {
    const response = await axios.get(`${Config.apiBaseAuthUrl}/auth/me`);
    const data = response?.data;

    if (!data) {
      throw 'Invalid response';
    }

    if (!(data.claims.a || data.claims.m || data.claims.is || data.claims.fwa || data.claims.fwm || data.claims.fws)) {
      //Is SME during setup?
      if (data.tasks && data.tasks.find(x => x.action == 'complete_sme_setup')) {
        data.company = { properties: {} };
        data.paySchedule = {};
      } else {
        throw 'Invalid credentials';
      }
    }

    await State.setMe(data);
    const user = data.user;
    user.company = data.company;
    user.paySchedule = data.pay_schedule;
    user.todos = data.tasks;
    return user;
  },

  patchUser(user, companyId) {
    return axios
      .patch(`${Config.apiBaseUrl}/companyadmins/${companyId}`, user)
      .then(response => response.data, handleError);
  },

  getAllEmployees() {
    return axios.get(`${Config.apiBaseUrl}/reports/employees`).then(response => response.data, handleError);
  },

  getEmployees(page = 0, pageSize = 25, sortColumn = undefined, sortColumnOrder = undefined, searchTerm = undefined) {
    return axios
      .get(
        `${Config.apiBaseUrl}/reports/employees?page=${page}&page_size=${pageSize}&sort_column=${
          sortColumn || ''
        }&sort_column_order=${sortColumnOrder || ''}&q=${searchTerm || ''}`,
      )
      .then(response => {
        return {
          total: response.data.total,
          employees: response.data.data,
        };
      }, handleError);
  },

  renderArticle(slug) {
    return axios
      .get(`${Config.apiBaseUrl}/articles/render/${slug}?help_center_type=PORTAL`)
      .then(response => response.data, handleError);
  },

  searchArticles(searchText, excludeSlugs) {
    return axios
      .get(
        `${Config.apiBaseUrl}/articles/search?help_center_type=PORTAL&search_text=${searchText}&exclude_slugs=${excludeSlugs}`,
      )
      .then(response => {
        return response.data;
      }, handleError);
  },

  getPaginatedEmployees(params) {
    const filteredParams = omitBy(params, p => isNull(p) || isUndefined(p));

    return axios
      .get(`${Config.apiBaseUrl}/sme/employees?` + stringify(filteredParams))
      .then(response => response.data, handleError);
  },

  getEmployee(employeeId) {
    return axios.get(`${Config.apiBaseUrl}/employees/${employeeId}`).then(response => response.data.data, handleError);
  },

  getEmployeeBalance(employeeId) {
    return axios
      .get(`${Config.apiBaseUrl}/balances/current/${employeeId}`)
      .then(response => response.data.data, handleError);
  },

  getSmePaySchedule(companyId, payScheduleId) {
    return axios
      .get(`${Config.apiBaseUrl}/sme/pay/${companyId}/${payScheduleId}`)
      .then(response => response.data.data, handleError);
  },

  updateSmePaySchedule(companyId, payScheduleId, paySchedule) {
    return axios
      .post(`${Config.apiBaseUrl}/sme/pay/${companyId}/${payScheduleId}`, paySchedule)
      .then(response => response.data.data, handleError);
  },

  createSmePaySchedule(companyId, paySchedule) {
    return axios
      .post(`${Config.apiBaseUrl}/sme/pay/${companyId}`, paySchedule)
      .then(response => response.data.data, handleError);
  },

  deleteSmePaySchedule(companyId, paySchedule) {
    return axios
      .delete(`${Config.apiBaseUrl}/sme/pay/${companyId}`, { data: paySchedule })
      .then(response => response.data.data, handleError);
  },

  getPaySchedules() {
    return axios.get(`${Config.apiBaseUrl}/payschedules/`).then(response => response.data, handleError);
  },

  getCompanyPaySchedules(companyId) {
    return axios
      .get(`${Config.apiBaseUrl}/payschedules/company/${companyId}/used`)
      .then(response => response.data, handleError);
  },

  getAllCompanyPayschedules(companyId) {
    return axios.get(`${Config.apiBaseUrl}/sme/pay/${companyId}`).then(response => response.data, handleError);
  },

  getPayScheduleDates(payScheduleId) {
    return axios
      .get(`${Config.apiBaseUrl}/payschedules/${payScheduleId}/dates`)
      .then(response => response.data.data, handleError);
  },

  getNextPaySchedules(companyId) {
    return axios
      .get(`${Config.apiBaseUrl}/payschedules/${companyId}/next`)
      .then(response => response.data.data, handleError);
  },

  getCompanyFieldPermissions(companyId) {
    return axios
      .get(`${Config.apiBaseUrl}/companies/${companyId}/fieldpermissions`)
      .then(response => response.data.data, handleError);
  },

  getDivisions(employeeId = undefined) {
    let querystring = '';
    if (employeeId) {
      querystring = `?employee_id=${employeeId}`;
    }

    return axios.get(`${Config.apiBaseUrl}/divisions/${querystring}`).then(response => response.data, handleError);
  },
  getDivision(divisionId = undefined) {
    return axios.get(`${Config.apiBaseUrl}/divisions/${divisionId}`).then(response => response.data, handleError);
  },
  addDivision(division) {
    return axios.post(`${Config.apiBaseUrl}/divisions/`, division).then(response => response.data.data, handleError);
  },
  patchDivision(division) {
    return axios
      .patch(`${Config.apiBaseUrl}/divisions/${division.division_id}`, division)
      .then(response => response.data, handleError);
  },
  deleteDivision(divisionId) {
    return axios
      .delete(`${Config.apiBaseUrl}/sme/divisions/${divisionId}`)
      .then(response => response.data.data, handleError);
  },

  getDivisionEmployees(divisionId = undefined) {
    return axios
      .get(`${Config.apiBaseUrl}/divisions/${divisionId}/employees`)
      .then(response => response.data, handleError);
  },

  getDivisionEmployeesCount() {
    const companyId = State.state.company.company_id;
    return axios
      .get(`${Config.apiBaseUrl}/sme/divisions/${companyId}/employees/count`)
      .then(response => response.data, handleError);
  },

  getEmployeeDivisionInfo() {
    const companyId = State.state.company.company_id;
    return axios
      .get(`${Config.apiBaseUrl}/sme/divisions/${companyId}/employees/info`)
      .then(response => response.data, handleError);
  },

  addDivisionEmployee(divisionId, employeeId) {
    return axios
      .post(`${Config.apiBaseUrl}/divisions/${divisionId}/employee`, stringify({ employee_id: employeeId }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then(response => {
        this.getFilters();
        return response.data.data;
      }, handleError);
  },

  removeDivisionEmployee(divisionId, employeeId) {
    return axios
      .delete(`${Config.apiBaseUrl}/divisions/${divisionId}/employees`, { data: [employeeId] })
      .then(response => {
        this.getFilters();
        return response.data.data;
      }, handleError);
  },

  getDivisionManagers(divisionId = undefined) {
    return axios
      .get(`${Config.apiBaseUrl}/divisions/${divisionId}/managers`)
      .then(response => response.data, handleError);
  },

  postDivisionManager(divisionId = undefined, manager) {
    return axios
      .post(`${Config.apiBaseUrl}/divisions/${divisionId}/managers`, manager)
      .then(response => response.data.data, handleError);
  },

  deleteDivisionManager(divisionId = undefined, manager) {
    return axios
      .delete(`${Config.apiBaseUrl}/divisions/${divisionId}/manager`, { data: { user_id: manager } })
      .then(response => response.data.data, handleError);
  },

  postEmployee(employee) {
    return axios.post(`${Config.apiBaseUrl}/employees/`, employee).then(response => {
      this.getFilters();
      return response.data;
    }, handleError);
  },
  updateEmployee(employee) {
    return axios.patch(`${Config.apiBaseUrl}/employees/${employee.employee_id}`, employee).then(response => {
      this.getFilters();
      return response.data;
    }, handleError);
  },

  updateEmployeeState(employeeId, employeeState) {
    // absolute block on enrolling employees via the portal if not eligible. just in case anyone slips through somehow
    if (employeeState.state === 'ENROLLING') {
      const adminCanEnrollEmployees =
        State.state.company?.properties?.b2b_features?.variations_whitelist?.includes('CAN_ENROLL_EMPLOYEES');
      if (!adminCanEnrollEmployees) {
        throw new Error(
          'You cannot enroll employees via the portal. Employees must be enrolled through your integration.',
        );
      }
    }
    return axios.patch(`${Config.apiBaseUrl}/employees/${employeeId}/state`, employeeState).then(response => {
      this.getFilters();
      return response.data;
    }, handleError);
  },
  getEmployeeHistory(employeeId) {
    return axios
      .get(`${Config.apiBaseUrl}/employees/${employeeId}/history`)
      .then(response => response.data, handleError);
  },

  getEmployeeEnrollable(employeeId) {
    return axios
      .get(`${Config.apiBaseUrl}/employees/${employeeId}/enrollable`)
      .then(response => response.data.enrollable, handleError);
  },

  employeeOptOut(employeeId, optOut) {
    return axios
      .post(`${Config.apiBaseUrl}/useroptouts/employee/${employeeId}/optout`, stringify(optOut), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then(response => {
        this.getFilters();
        return response.data;
      }, handleError);
  },

  employeeOptOutComplete(employeeId) {
    return axios.post(`${Config.apiBaseUrl}/useroptouts/employee/${employeeId}/complete`).then(response => {
      this.getFilters();
      return response.data;
    }, handleError);
  },

  employeeOptIn(employeeId, reason) {
    return axios
      .post(`${Config.apiBaseUrl}/useroptouts/employee/${employeeId}/reverse`, stringify({ reason: reason }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then(response => {
        this.getFilters();
        return response.data;
      }, handleError);
  },
  employeeDeactivate(employeeId, reason) {
    return axios
      .post(`${Config.apiBaseUrl}/employees/${employeeId}/deactivate`, stringify({ reason: reason }), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then(response => {
        this.getFilters();
        return response.data;
      }, handleError);
  },

  checkEmployeeEverEnrolled(employeeId) {
    return axios.get(`${Config.apiBaseUrl}/sme/${employeeId}/ever-enrolled`).then(response => {
      return response.data;
    }, handleError);
  },

  employeeSetTerminationDate(employeeId, date) {
    return axios.post(`${Config.apiBaseUrl}/sme/${employeeId}/set-termination-date?date=${date}`).then(response => {
      this.getFilters();
      return response.data;
    }, handleError);
  },

  async postLastDayPay(payload) {
    return axios
      .post(`${Config.apiBaseUrl}/transfers/last-day-pay`, payload)
      .then(response => response.data, handleError);
  },
  getLastDayPayReport({ starts_on, ends_on }) {
    const querystring = `?starts_on=${starts_on}&ends_on=${ends_on}`;

    return axios
      .get(`${Config.apiBaseUrl}/reports/last-day-report${querystring}`)
      .then(response => response.data, handleError);
  },

  employeeResetPassword(employeeId, reason, requestedByUserId, email) {
    return axios
      .put(`${Config.apiBaseAuthUrl}/auth/forgot`, {
        employee_id: employeeId,
        requested_by_user_id: requestedByUserId,
        reason: reason,
        email: email,
      })
      .then(response => response.data, handleError);
  },
  employeeInvite(employeeId) {
    return axios
      .post(`${Config.apiBaseUrl}/employees/${employeeId}/invite`)
      .then(response => response.data, handleError);
  },

  getTransfers(params) {
    return axios
      .get(`${Config.apiBaseUrl}/reports/transfers?` + stringify(params))
      .then(response => response.data, handleError);
  },

  getWages(params) {
    return axios
      .get(`${Config.apiBaseUrl}/reports/wages?` + stringify(params))
      .then(response => response.data, handleError);
  },

  getAdoptionData(companyId) {
    return axios.get(`${Config.apiBaseUrl}/sme/adoption/${companyId}`).then(response => response.data, handleError);
  },

  getImpactSavings(companyId) {
    return axios.get(`${Config.apiBaseUrl}/impact/savings/${companyId}`).then(response => response.data, handleError);
  },

  getImpactRetention(companyId) {
    return axios.get(`${Config.apiBaseUrl}/impact/retention/${companyId}`).then(response => response.data, handleError);
  },

  getImpactAdvances(companyId) {
    return axios.get(`${Config.apiBaseUrl}/impact/advances/${companyId}`).then(response => response.data, handleError);
  },

  getImpactCostOfLiving(companyId) {
    return axios
      .get(`${Config.apiBaseUrl}/impact/livingcosts/${companyId}`)
      .then(response => response.data, handleError);
  },

  getAccountingReport(params) {
    let querystring = '';
    if (params) {
      querystring += `?input_date=${params.input_date}`;
    }
    return axios
      .get(`${Config.apiBaseUrl}/reports/accounting` + querystring)
      .then(response => response.data, handleError);
  },

  getEmployeeSalaryStatements(employeeId) {
    const params = {
      starts_on: moment().subtract(10, 'year').format('YYYY-MM-DD'),
      employee_id: employeeId,
      company_id: State.state.company.company_id,
    };
    return axios
      .get(`${Config.apiBaseUrl}/salaries/summary?` + stringify(params))
      .then(response => response.data.data, handleError);
  },

  getEmployeePaymentsReport() {
    const companyId = State.state.company.company_id;
    return axios
      .get(`${Config.apiBaseUrl}/reports/employees/payments/${companyId}`)
      .then(response => response.data, handleError);
  },

  downloadEmployeeSalaryStatement(salaryId) {
    return axios
      .get(`${Config.apiBaseUrl}/reports/salaries/${salaryId}/pdf`, { responseType: 'blob' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'statement.pdf');
        document.body.appendChild(link);
        link.click();
      });
  },

  downloadBankingReport(companyId, fileName) {
    return axios
      .get(`${Config.apiBaseUrl}/companies/integrations/${companyId}/sftp-report`, { responseType: 'blob' })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/csv' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
      });
  },

  getInvalidBankDetails() {
    return axios
      .get(`${Config.apiBaseUrl}/reports/payroll/invalid_bank_details`)
      .then(response => response.data, handleError);
  },
  getInvalidBankDetailsB2b() {
    const companyId = State.state.company.company_id;
    return axios
      .get(`${Config.apiBaseUrl}/sme/reports/invalid_bank_details/${companyId}`)
      .then(response => response.data, handleError);
  },

  getFailedSalaries(params) {
    const querystring = `?starts_on=${params.starts_on}&ends_on=${params.ends_on}`;
    return axios
      .get(`${Config.apiBaseUrl}/reports/payroll/failed_salaries` + querystring)
      .then(response => response.data, handleError);
  },

  getMissedSalaries(params) {
    const querystring = `?starts_on=${params.starts_on}&ends_on=${params.ends_on}`;
    return axios
      .get(`${Config.apiBaseUrl}/reports/payroll/missed_salaries` + querystring)
      .then(response => response.data, handleError);
  },

  getMissedSalariesB2b(params) {
    const companyId = State.state.company.company_id;
    const querystring = `?starts_on=${params.starts_on}&ends_on=${params.ends_on}`;
    return axios
      .get(`${Config.apiBaseUrl}/sme/reports/missed_salaries/${companyId}` + querystring)
      .then(response => response.data, handleError);
  },

  getAdmins() {
    const companyId = State.state.user.company.company_id;
    return axios.get(`${Config.apiBaseUrl}/companyadmins/${companyId}`).then(response => response.data, handleError);
  },

  addAdmin(companyId, admin) {
    return axios
      .post(`${Config.apiBaseUrl}/companyadmins/${companyId}`, admin)
      .then(response => response.data, handleError);
  },

  deleteAdmin(companyId, userId) {
    return axios
      .delete(`${Config.apiBaseUrl}/companyadmins/${companyId}`, { data: { user_id: userId } })
      .then(response => response.data, handleError);
  },

  getIntegrations(companyId) {
    IntegrationsState.setLoading();
    return axios.get(`${Config.apiBaseUrl}/companies/integrations/${companyId}`).then(response => {
      IntegrationsState.setIntegrations(response.data.data);
      return response.data;
    }, handleError);
  },

  getIntegrationResults(integrationId) {
    return axios
      .get(`${Config.apiBaseUrl}/companies/integrations/${integrationId}/results`)
      .then(response => response.data, handleError);
  },

  getAvailableSmeIntegrations() {
    return axios.get(`${Config.apiBaseUrl}/sme/integrations`).then(response => {
      return response.data;
    }, handleError);
  },

  postIntegrationTenancy(companyIntegrationId, payload) {
    const companyId = State.state.company.company_id;
    return axios
      .post(`${Config.apiBaseUrl}/sme/integrations/tenancy/${companyId}/${companyIntegrationId}`, payload)
      .then(response => {
        return response.data;
      }, handleError);
  },

  getFilters() {
    store.commit('filters/setLoading');
    return axios.get(`${Config.apiBaseUrl}/dashboards/filters`).then(response => {
      store.commit('filters/setFilters', response.data);
      return response.data;
    }, handleError);
  },

  getRegistration(registrationId) {
    return axios
      .get(`${Config.apiBaseUrl}/registrations/${registrationId}`)
      .then(response => response.data, handleError);
  },

  patchRegistration(registrationId, registration) {
    return axios.patch(`${Config.apiBaseUrl}/registrations/${registrationId}`, registration).then(response => {
      this.getFilters();
      return response;
    }, handleError);
  },

  getRegistrationEmployee(registrationId) {
    return axios
      .get(`${Config.apiBaseUrl}/registrations/${registrationId}/employee`)
      .then(response => response.data, handleError);
  },

  postFile(companyId, fileName, content, metadata) {
    metadata = { ...metadata, source: 'PARTNER_PORTAL' };

    // Note: content should be instance of Uint8Array
    if (!(content instanceof Uint8Array)) {
      throw new Error('File content must be supplied as Uint8Array');
    }

    return axios
      .post(
        `${Config.apiBaseUrl}/companies/integrations/${companyId}/file`,
        stringify({
          file: base64.fromByteArray(content),
          file_name: fileName,
          metadata: JSON.stringify(metadata),
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      )
      .then(
        response => response.data,
        error => {
          if (error.response) {
            throw error.response.data;
          } else {
            throw error;
          }
        },
      );
  },

  // eslint-disable-next-line no-unused-vars
  postClientUpload(companyId, fileName, content, metadata) {
    return axios
      .post(
        `${Config.apiBaseUrl}/companies/${companyId}/client-uploads`,
        stringify({
          file: base64.fromByteArray(content),
          file_name: fileName,
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      )
      .then(
        response => response.data,
        error => {
          if (error.response) {
            throw error.response.data;
          } else {
            throw error;
          }
        },
      );
  },

  validateFile(companyId, fileName, content, entity) {
    // Note: content should be instance of Uint8Array
    if (!(content instanceof Uint8Array)) {
      throw new Error('File content must be supplied as Uint8Array');
    }

    return axios
      .post(
        `${Config.apiBaseUrl}/companies/integrations/${companyId}/file/validate`,
        stringify({
          file: base64.fromByteArray(content),
          file_name: fileName,
          source: 'PARTNER_PORTAL',
          entity: entity,
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      )
      .then(
        response => response.data,
        error => {
          if (error.response) {
            throw error.response.data;
          } else {
            throw error;
          }
        },
      );
  },

  validatePaymentRunCSV(companyId, fileName, content, metadata) {
    metadata = { ...metadata, source: 'SME_PORTAL' };

    if (!(content instanceof Uint8Array)) {
      throw new Error('File content must be supplied as Uint8Array');
    }

    return axios
      .post(
        `${Config.apiBaseUrl}/paywithwagestream/validate/${companyId}`,
        stringify({
          file: base64.fromByteArray(content),
          file_name: fileName,
          metadata: JSON.stringify(metadata),
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      )
      .then(
        response => {
          return {
            ...response.data,
            content, // this is needed to be able to pass on the content to the BE in order to create payment run
          };
        },
        error => {
          if (error.response) {
            throw error.response.data;
          } else {
            throw error;
          }
        },
      );
  },

  uploadPaymentRunCSV(companyId, fileName, content, payImmediate, metadata) {
    metadata = { ...metadata, source: 'SME_PORTAL' };

    if (!(content instanceof Uint8Array)) {
      throw new Error('File content must be supplied as Uint8Array');
    }

    return axios
      .post(
        `${Config.apiBaseUrl}/paywithwagestream/upload/${companyId}`,
        stringify({
          file: base64.fromByteArray(content),
          file_name: fileName,
          pay_immediate: payImmediate,
          metadata: JSON.stringify(metadata),
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      )
      .then(
        response => response.data,
        error => {
          if (error.response) {
            throw error.response.data;
          } else {
            throw error;
          }
        },
      );
  },

  getPaymentRunHistory(companyId) {
    return axios
      .get(`${Config.apiBaseUrl}/paywithwagestream/history/${companyId}`)
      .then(response => response.data, handleError);
  },

  getBankPayHistory(companyId) {
    return axios
      .get(`${Config.apiBaseUrl}/sme/weekly_pay/${companyId}/history`)
      .then(response => response.data.data, handleError);
  },

  deletePaymentRun(companyId, reference) {
    const payload = { reference: reference };
    return axios
      .delete(`${Config.apiBaseUrl}/paywithwagestream/remove/${companyId}`, { data: payload })
      .then(response => response.data, handleError);
  },

  forcePaymentRun(companyId, reference) {
    const payload = { reference: reference };
    return axios
      .post(`${Config.apiBaseUrl}/paywithwagestream/force/${companyId}`, payload)
      .then(response => response.data, handleError);
  },

  postAnalytics(eventName, params) {
    if (process.env.VUE_APP_ANALYTICS_URL) {
      try {
        params = params || {};
        params.environment = portalEnvs[document.location.hostname] || 'dev';
        params.impersonated = State.state?.claims?.imp;
        params.page = window.location.pathname;
        const payload = {
          event: eventName,
          params: params,
          visitor_id: State.state?.visitor?.visitor_id,
          user_id: State.state?.user?.user_id,
          company_id: State.state?.company?.company_id,
          app_id: document.location.hostname,
        };
        return axios.post(process.env.VUE_APP_ANALYTICS_URL, payload).then(response => response.data);
      } catch (error) {
        console.error('Error posting analytics event', error);
      }
    }
  },
  // Dashboard

  async getTransfersCount() {
    return await axios
      .get(`${Config.apiBaseUrl}/dashboards/total_transfers_count`)
      .then(response => response.data.data, handleError);
  },

  async getAppVisits(params) {
    return await axios
      .get(`${Config.apiBaseUrl}/reports/app_visits?${stringify(params)}`)
      .then(response => response.data.data, handleError);
  },

  async getEnrolmentSnapshot(params) {
    return await axios
      .get(`${Config.apiBaseUrl}/dashboards/enrollment_snapshot?${stringify(params)}`)
      .then(response => response.data.data, handleError);
  },

  getReferralCode(companyId) {
    return axios.get(`${Config.apiBaseUrl}/sme/referrals/${companyId}`).then(response => response.data, handleError);
  },

  smeCompanyGetRelevantPeople(companyId) {
    return axios
      .get(`${Config.apiBaseUrl}/compliance/company/gb/${companyId}/relevant_people`)
      .then(response => response.data.data, handleError);
  },
  smeCompanyCreateRelevantPersonConnection(userId, payload) {
    return axios
      .post(`${Config.apiBaseUrl}/compliance/user/gb/${userId}/relevant_person`, payload)
      .then(response => response.data);
  },
  smeCompanyVerifyRelevantPersonConnection(userId, payload) {
    return axios
      .post(`${Config.apiBaseUrl}/compliance/user/gb/${userId}`, payload)
      .then(response => response.data.data, handleError);
  },
  smeInviteRelevantPerson(relevantPersonId, email, companyId) {
    const payload = {
      relevant_person_id: relevantPersonId,
      email: email,
      company_id: companyId,
    };
    return axios
      .post(`${Config.apiBaseUrl}/compliance/user/gb/invite`, payload)
      .then(response => response.data, handleError);
  },
  smeCompanySearch(q) {
    const escapedQ = encodeURIComponent(q);
    return axios
      .get(`${Config.apiBaseUrl}/sme/company/gb/search?q=${escapedQ}`)
      .then(response => response.data.data, handleError);
  },
  smeCompanyGetTaskList(companyId) {
    return axios
      .get(`${Config.apiBaseUrl}/sme/onboarding/${companyId}`)
      .then(response => response.data.data, handleError);
  },
  smeEmployeesAddBulk(companyId, fileName, content, metadata) {
    metadata = { ...metadata, source: 'SME_PORTAL' };

    if (!(content instanceof Uint8Array)) {
      throw new Error('File content must be supplied as Uint8Array');
    }

    return axios
      .post(
        `${Config.apiBaseUrl}/sme/${companyId}/employees/bulk`,
        stringify({
          file: base64.fromByteArray(content),
          file_name: fileName,
          metadata: JSON.stringify(metadata),
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      )
      .then(response => response.data, handleError);
  },
  companyFundingBalance(companyId) {
    return axios.get(`${Config.apiBaseUrl}/sme/${companyId}/funding`).then(response => response.data, handleError);
  },
  companyPayrollBalance(companyId) {
    return axios.get(`${Config.apiBaseUrl}/sme/${companyId}/payroll`).then(response => response.data, handleError);
  },

  smeUpdateCompanyProperties(companyId, properties) {
    return axios
      .patch(`${Config.apiBaseUrl}/sme/companies/${companyId}`, properties)
      .then(response => response.data, handleError);
  },

  getCompanyLaunchStatus(companyId) {
    return axios.get(`${Config.apiBaseUrl}/sme/${companyId}/launch`).then(response => response.data, handleError);
  },
  launchCompany(companyId) {
    return axios.post(`${Config.apiBaseUrl}/sme/${companyId}/launch`, {}).then(response => response.data, handleError);
  },
  checkUsername(username) {
    const formData = new FormData();
    formData.append('username', username);
    return axios
      .post(`${Config.apiBaseAuthUrl}/auth/employees`, formData, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then(response => response.data, handleError);
  },
  smeSetup(payload) {
    return axios.post(`${Config.apiBaseUrl}/sme/setup/self-serve`, payload).then(response => {
      if (response.data.auth) {
        setToken(response.data.auth.access_token);
        setRefreshToken(response.data.auth.refresh_token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.auth.access_token}`;
      }
      return response.data.data;
    }, handleError);
  },
  smeSubscribe(payload) {
    const companyId = State.state.company.company_id;
    return axios.post(`${Config.apiBaseUrl}/sme/subscribe/${companyId}`, payload).then(response => {
      return response.data.data;
    }, handleError);
  },
  addSubscription(companyId, payload) {
    return axios
      .post(`${Config.apiBaseUrl}/stripe/subscription/${companyId}`, payload)
      .then(response => response.data, handleError);
  },
  getSubscription(companyId) {
    return axios.get(`${Config.apiBaseUrl}/stripe/subscription/${companyId}`).then(response => response.data.data);
  },
  newStripePortalSession(companyId) {
    return axios
      .post(`${Config.apiBaseUrl}/stripe/create-customer-portal-session/${companyId}`)
      .then(response => response.data.data);
  },
  getStripePaymentIntent(companyId) {
    return axios.get(`${Config.apiBaseUrl}/stripe/intent/${companyId}`).then(response => response.data.data);
  },
  postLogSuccessfulStripePaymentIntent(companyId, payload) {
    return axios.post(`${Config.apiBaseUrl}/stripe/intent/${companyId}`, payload).then(response => response.data);
  },
  postCreateStripePaymentCheckoutSession(companyId, payload) {
    return axios
      .post(`${Config.apiBaseUrl}/stripe/create-checkout-session/${companyId}`, payload)
      .then(response => response.data.data);
  },
  getKYCStatus(userId) {
    return axios
      .get(`${Config.apiBaseUrl}/compliance/user/gb/${userId}/kyc_status`)
      .then(response => response.data.data);
  },
  smeGetTerms() {
    return axios.get(`${Config.apiBaseUrl}/sme/terms`).then(res => res.data.data);
  },
  getAgreement(agreementId) {
    return axios.get(`${Config.apiBaseUrl}/agreements/${agreementId}`).then(res => res.data.data, handleError);
  },
  getAcceptedAgreements() {
    const companyId = State.state.company.company_id;
    return axios.get(`${Config.apiBaseUrl}/agreements/company/${companyId}`).then(res => res.data.data, handleError);
  },
  patchUserProperties(payload) {
    const userId = State.state.user.user_id;
    return axios
      .patch(`${Config.apiBaseUrl}/users/${userId}/information`, payload)
      .then(res => res?.data?.data, handleError);
  },
  smeGetPaymentPlans() {
    return axios.get(`${Config.apiBaseUrl}/sme/plans`).then(res => res.data);
  },
  getFundingPosition() {
    return axios.get(`${Config.apiBaseUrl}/reports/position/monthly`).then(response => response.data, handleError);
  },
  getFundingPositionSummary() {
    return axios.get(`${Config.apiBaseUrl}/reports/position/summary`).then(response => response.data, handleError);
  },
  smeGetSelfFundedTransactions(companyId, page = 0, size = 20) {
    return axios
      .get(`${Config.apiBaseUrl}/sme/${companyId}/transactions?page=${page}&size=${size}`)
      .then(res => res.data);
  },
  smeAddSingleShift(shift) {
    const companyId = State.state.company.company_id;
    return axios
      .post(`${Config.apiBaseUrl}/sme/${companyId}/shifts/single`, shift)
      .then(response => response.data, handleError);
  },
  smeAddBulkShifts(companyId, fileName, content, metadata) {
    metadata = { ...metadata, source: 'SME_PORTAL' };

    // Note: content should be instance of Uint8Array
    if (!(content instanceof Uint8Array)) {
      throw new Error('File content must be supplied as Uint8Array');
    }

    return axios
      .post(
        `${Config.apiBaseUrl}/sme/${companyId}/shifts/bulk`,
        stringify({
          file: base64.fromByteArray(content),
          file_name: fileName,
          metadata: JSON.stringify(metadata),
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      )
      .then(
        response => response.data,
        error => {
          if (error.response) {
            throw error.response.data;
          } else {
            throw error;
          }
        },
      );
  },
  smeGetAveragePendingTime() {
    const companyId = State.state.company.company_id;
    return axios
      .get(`${Config.apiBaseUrl}/sme/dashboard/pending_time/${companyId}`)
      .then(response => response.data, handleError);
  },
  smeGetShiftsInLastNDays(days) {
    const companyId = State.state.company.company_id;
    return axios
      .get(`${Config.apiBaseUrl}/sme/dashboard/last_shift_sync/${companyId}?days=${days}`)
      .then(response => response.data, handleError);
  },
  smeGetEmployeesWithoutShifts(days) {
    const companyId = State.state.company.company_id;
    return axios
      .get(`${Config.apiBaseUrl}/sme/dashboard/employees_without_shifts/${companyId}?days=${days}`)
      .then(response => response.data, handleError);
  },
  async smeGetCanAutoEnroll() {
    const companyId = State.state.company.company_id;
    return await axios
      .get(`${Config.apiBaseUrl}/sme/dashboard/can_auto_enroll/${companyId}`)
      .then(response => response.data.data, handleError);
  },
  smeGetEmployeesWithoutPayments() {
    const companyId = State.state.company.company_id;
    return axios
      .get(`${Config.apiBaseUrl}/sme/reports/employees_never_receieved_salary/${companyId}`)
      .then(response => response.data, handleError);
  },
  smeGetEmployeesWithSavingsAccounts() {
    const companyId = State.state.company.company_id;
    return axios
      .get(`${Config.apiBaseUrl}/sme/dashboard/total_savings/${companyId}`)
      .then(response => response.data, handleError);
  },
  getLaunchState(companyId) {
    return axios.get(`${Config.apiBaseUrl}/sme/${companyId}/launchstate`).then(response => response.data, handleError);
  },
  smeIsPayWith() {
    const companyId = State.state.company.company_id;
    return axios
      .get(`${Config.apiBaseUrl}/sme/dashboard/is_pay_with/${companyId}`)
      .then(response => response.data, handleError);
  },
  getNShiftsAfterOffsetAndDeletionEligiblity(params) {
    const companyId = State.state.company.company_id;
    const filteredParams = omitBy(params, p => isNull(p) || isUndefined(p));

    return axios
      .get(`${Config.apiBaseUrl}/sme/${companyId}/shifts?` + stringify(filteredParams))
      .then(response => response.data, handleError);
  },
  getPaginatedShifts(params) {
    const companyId = State.state.company.company_id;
    const filteredParams = omitBy(params, p => isNull(p) || isUndefined(p));

    return axios
      .get(`${Config.apiBaseUrl}/sme/${companyId}/shifts?` + stringify(filteredParams))
      .then(response => response.data, handleError);
  },
  deleteShift(shiftId) {
    return axios.delete(`${Config.apiBaseUrl}/sme/${shiftId}/shifts/delete`);
  },
  smePostWhatsAppOptIn(optIn, userId, agreementId) {
    const companyId = State.state.company.company_id;
    const payload = {
      opt_in: optIn,
      user_id: userId,
      agreement_id: agreementId,
    };
    return axios
      .post(`${Config.apiBaseUrl}/sme/onboarding/${companyId}/whatsapp`, payload)
      .then(response => response.data, handleError);
  },
  smeGetWhatsAppOptIn() {
    const companyId = State.state.company.company_id;
    return axios.get(`${Config.apiBaseUrl}/sme/onboarding/${companyId}/whatsapp`);
  },
  patchSmeEmployeeBanking(employeeId, accountNumber, sortCode, beneficiary_type) {
    const payload = {
      account_number: accountNumber,
      sort_code: sortCode,
    };

    if (beneficiary_type) {
      payload['beneficiary_type'] = beneficiary_type;
    }

    return axios
      .patch(`${Config.apiBaseUrl}/sme/${employeeId}/employees/banking`, payload)
      .then(response => response.data, handleError);
  },
  switchToHourly(employeeId, salaryProperties, date) {
    return axios
      .patch(`${Config.apiBaseUrl}/sme/${employeeId}/shifts/salary_to_hourly?since_date=${date}`, salaryProperties)
      .then(response => response.data, handleError);
  },
  reactivateEmployee(employeeId, payload) {
    const companyId = State.state.company.company_id;
    return axios
      .patch(`${Config.apiBaseUrl}/sme/${companyId}/${employeeId}/reactivate`, payload)
      .then(response => response.data, handleError);
  },
  getWeeklyPayMetrics() {
    const companyId = State.state.company.company_id;
    return axios
      .get(`${Config.apiBaseUrl}/sme/metrics/weekly_pay/${companyId}`)
      .then(response => response.data, handleError);
  },
  getAdoptionMetrics(timeSeries, divisionId) {
    const companyId = State.state.company.company_id;
    return axios
      .get(
        `${Config.apiBaseUrl}/sme/metrics/adoption/${companyId}?period=${timeSeries}${
          divisionId ? `&division_id=${divisionId}` : ''
        }&exclude_incomplete=False`,
      )
      .then(response => response.data, handleError);
  },
  getAppFeaturesMetrics(timeSeries, divisionId) {
    const companyId = State.state.company.company_id;
    return axios
      .get(
        `${Config.apiBaseUrl}/sme/metrics/app_features/${companyId}?period=${timeSeries}${
          divisionId ? `&division_id=${divisionId}` : ''
        }&exclude_incomplete=False`,
      )
      .then(response => response.data, handleError);
  },
  getAppLoginsMetrics(timeSeries, divisionId) {
    const companyId = State.state.company.company_id;
    return axios
      .get(
        `${Config.apiBaseUrl}/sme/metrics/app_logins/${companyId}?period=${timeSeries}${
          divisionId ? `&division_id=${divisionId}` : ''
        }&exclude_incomplete=False`,
      )
      .then(response => response.data, handleError);
  },
  getBuildMetrics(timeSeries, divisionId) {
    const companyId = State.state.company.company_id;
    return axios
      .get(
        `${Config.apiBaseUrl}/sme/metrics/build/${companyId}?period=${timeSeries}${
          divisionId ? `&division_id=${divisionId}` : ''
        }&exclude_incomplete=False`,
      )
      .then(response => response.data, handleError);
  },
  getSdlMetrics(timeSeries, divisionId) {
    const companyId = State.state.company.company_id;
    return axios
      .get(
        `${Config.apiBaseUrl}/sme/metrics/sdl/${companyId}?period=${timeSeries}${
          divisionId ? `&division_id=${divisionId}` : ''
        }`,
      )
      .then(response => response.data, handleError);
  },
  getSdlPurposeMetrics(timeSeries, divisionId) {
    const companyId = State.state.company.company_id;
    return axios
      .get(
        `${Config.apiBaseUrl}/sme/metrics/sdl_purpose/${companyId}?period=${timeSeries}${
          divisionId ? `&division_id=${divisionId}` : ''
        }`,
      )
      .then(response => response.data, handleError);
  },
  getStreamMetrics(timeSeries, divisionId) {
    const companyId = State.state.company.company_id;
    return axios
      .get(
        `${Config.apiBaseUrl}/sme/metrics/stream/${companyId}?period=${timeSeries}${
          divisionId ? `&division_id=${divisionId}` : ''
        }&exclude_incomplete=False`,
      )
      .then(response => response.data, handleError);
  },
  getStreamReasonsMetrics(timeSeries, divisionId) {
    const companyId = State.state.company.company_id;
    return axios
      .get(
        `${Config.apiBaseUrl}/sme/metrics/stream_reasons/${companyId}?period=${timeSeries}${
          divisionId ? `&division_id=${divisionId}` : ''
        }`,
      )
      .then(response => response.data, handleError);
  },
  getTrackMetrics(timeSeries, divisionId) {
    const companyId = State.state.company.company_id;
    return axios
      .get(
        `${Config.apiBaseUrl}/sme/metrics/track/${companyId}?period=${timeSeries}${
          divisionId ? `&division_id=${divisionId}` : ''
        }&exclude_incomplete=False`,
      )
      .then(response => response.data, handleError);
  },
  getShiftCountMetrics(timeSeries, divisionId) {
    const companyId = State.state.company.company_id;
    return axios
      .get(
        `${Config.apiBaseUrl}/sme/metrics/shift_count/${companyId}?period=${timeSeries}${
          divisionId ? `&division_id=${divisionId}` : ''
        }`,
      )
      .then(response => response.data, handleError);
  },
  getShiftEmployeesMetrics(timeSeries, divisionId) {
    const companyId = State.state.company.company_id;
    return axios
      .get(
        `${Config.apiBaseUrl}/sme/metrics/employee_shifts/${companyId}?period=${timeSeries}${
          divisionId ? `&division_id=${divisionId}` : ''
        }`,
      )
      .then(response => response.data, handleError);
  },
  getShiftHoursMetrics(timeSeries, divisionId) {
    const companyId = State.state.company.company_id;
    return axios
      .get(
        `${Config.apiBaseUrl}/sme/metrics/hours_by_employee/${companyId}?period=${timeSeries}${
          divisionId ? `&division_id=${divisionId}` : ''
        }`,
      )
      .then(response => response.data, handleError);
  },
  getShiftDivisionsMetrics() {
    const companyId = State.state.company.company_id;
    return axios
      .get(`${Config.apiBaseUrl}/sme/metrics/divisions/${companyId}`)
      .then(response => response.data, handleError);
  },
  acceptTerms(termsId, notify) {
    const companyId = State.state.company.company_id;
    const userId = State.state.user.user_id;
    const payload = {
      termsId,
      companyId,
      userId,
      notify: notify === undefined ? true : notify,
    };
    return axios.post(`${Config.apiBaseUrl}/sme/terms`, payload).then(response => response.data, handleError);
  },
  setCompanyReturnPaymentBankDetails(payload) {
    const companyId = State.state.company.company_id;
    return axios
      .post(`${Config.apiBaseUrl}/companies/${companyId}/banking`, payload)
      .then(response => response.data, handleError);
  },
  getAuthSwitch() {
    const companyId = State?.state?.company?.company_id;
    // needs to be handled because is called on every page
    // meaning that it will always be called when a user times out and refreshes/ goes to a new page
    if (companyId) {
      return axios
        .get(`${Config.apiBaseAuthUrl}/auth/switch/company/${companyId}`)
        .then(response => response.data, handleError);
    } else {
      return undefined;
    }
  },
  postAuthSwitch(targetCompanyId) {
    const companyId = State.state.company.company_id;
    return axios
      .post(`${Config.apiBaseAuthUrl}/auth/switch/company/${companyId}`, { target_company_id: targetCompanyId })
      .then(response => {
        const data = response.data;
        if (data.is_valid) {
          handleLogin(data.access_token, data.refresh_token);
          window.location.reload();
        }
      }, handleError);
  },
  performEmailAction(token) {
    return axios.get(`${Config.apiBaseUrl}/sme/emailactions/${token}`).then(response => response.data, handleError);
  },
  toggleSalarySignOffEmails(companyId, optIn) {
    const payload = {
      opt_in: optIn,
    };
    return axios
      .post(`${Config.apiBaseUrl}/sme/salary-sign-off/${companyId}/toggle`, payload)
      .then(response => response.data, handleError);
  },
  bulkSalarySignOff(actionId) {
    return axios
      .post(`${Config.apiBaseUrl}/sme/salary-sign-off/portal-confirm/${actionId}`)
      .then(response => response.data, handleError);
  },
  getOffCycleConfig() {
    return axios.get(`${Config.apiBaseUrl}/off-cycle-payments/config`).then(response => response.data, handleError);
  },
  getOffCyclePayments() {
    return axios.get(`${Config.apiBaseUrl}/off-cycle-payments/`).then(response => {
      return {
        ...response.data,
        data: response.data.data.map(item => ({
          ...item,
          created_at: item.created_at + 'Z',
          submitted_at: readDatetimeAsUTC(item.submitted_at),
        })),
      };
    }, handleError);
  },
  getOffCyclePaymentBatch(batchId) {
    return axios.get(`${Config.apiBaseUrl}/off-cycle-payments/groups/${batchId}`).then(response => {
      return {
        ...response.data,
        data: {
          ...response.data.data,
          created_at: response.data.data.created_at + 'Z',
          submitted_at: readDatetimeAsUTC(response.data.data.submitted_at),
          properties: {
            ...response.data.data.properties,
            deferred_payment_datetime: readDatetimeAsUTC(response.data.data.properties?.deferred_payment_datetime),
          },
        },
      };
    }, handleError);
  },
  deleteOffCycleBatch(batchId) {
    return axios
      .delete(`${Config.apiBaseUrl}/off-cycle-payments/groups/${batchId}`)
      .then(response => response.data, handleError);
  },
  createOffCycleSinglePayment(data) {
    const updatedPayload = {
      ...data,
      properties: {
        ...data.properties,
        deferred_payment_datetime: setDateTimeToUTC(data.properties?.deferred_payment_datetime),
      },
    };

    return axios
      .post(`${Config.apiBaseUrl}/off-cycle-payments/single`, updatedPayload)
      .then(response => response.data, handleError);
  },
  createOffCycleBatch(data) {
    const updatedPayload = {
      ...data,
      properties: {
        ...data.properties,
        deferred_payment_datetime: setDateTimeToUTC(data.properties?.deferred_payment_datetime),
      },
    };

    return axios
      .post(`${Config.apiBaseUrl}/off-cycle-payments/`, updatedPayload)
      .then(response => response.data, handleError);
  },
  saveOffCycleBatch(batchId, data) {
    const updatedPayload = {
      ...data,
      properties: {
        ...data.properties,
        deferred_payment_datetime: setDateTimeToUTC(data.properties?.deferred_payment_datetime),
      },
    };

    return axios
      .put(`${Config.apiBaseUrl}/off-cycle-payments/groups/${batchId}`, updatedPayload)
      .then(response => response.data, handleError);
  },
  processOffCycleBatch(batchId) {
    return axios
      .post(`${Config.apiBaseUrl}/off-cycle-payments/groups/${batchId}/process`)
      .then(response => response.data, handleError);
  },
  downloadOffCycleInvoice(batchId) {
    return axios
      .post(`${Config.apiBaseUrl}/off-cycle-payments/groups/${batchId}/download_invoice`, {}, { responseType: 'blob' })
      .then(response => response.data, handleError);
  },
  getFlexibleWorkClaims(params) {
    const companyId = State.state.company.company_id;

    return axios
      .get(`${Config.apiBaseUrl}/flexible_work/claims/company/${companyId}?${stringify(params)}`)
      .then(response => response.data, handleError);
  },
  deleteFlexibleWorkClaimApprove(claim) {
    const companyId = State.state.company.company_id;

    return axios
      .delete(`${Config.apiBaseUrl}/flexible_work/claims/claim/${companyId}/${claim.claim_id}/approve`)
      .then(response => response.data, handleError);
  },
  postFlexibleWorkClaimApprove(claim) {
    const companyId = State.state.company.company_id;

    return axios
      .post(`${Config.apiBaseUrl}/flexible_work/claims/claim/${companyId}/${claim.claim_id}/approve`)
      .then(response => response.data, handleError);
  },
  postFlexibleWorkClaimFulfill(claim) {
    const companyId = State.state.company.company_id;

    return axios
      .post(`${Config.apiBaseUrl}/flexible_work/claims/claim/${companyId}/${claim.claim_id}/fulfill`)
      .then(response => response.data, handleError);
  },
  patchFlexibleWorkEmployeePreferencesBulk(employees) {
    const companyId = State.state.company.company_id;

    return axios
      .patch(`${Config.apiBaseUrl}/flexible_work/preferences/${companyId}/bulk`, { employees })
      .then(response => response.data, handleError);
  },
  deleteFlexibleWorkShift(shiftId) {
    const companyId = State.state.company.company_id;

    return axios
      .delete(`${Config.apiBaseUrl}/flexible_work/shifts/${companyId}/${shiftId}`)
      .then(response => response.data, handleError);
  },
  getFlexibleWorkShifts(params) {
    const companyId = State.state.company.company_id;

    return axios
      .get(`${Config.apiBaseUrl}/flexible_work/shifts/${companyId}?${stringify(params)}`)
      .then(response => response.data, handleError);
  },
  getFlexibleWorkShift(shiftId) {
    const companyId = State.state.company.company_id;

    return axios
      .get(`${Config.apiBaseUrl}/flexible_work/shifts/${companyId}/${shiftId}`)
      .then(response => response.data, handleError);
  },
  postFlexibleWorkShift(shift) {
    const companyId = State.state.company.company_id;

    return axios
      .post(`${Config.apiBaseUrl}/flexible_work/shifts/${companyId}`, shift)
      .then(response => response.data, handleError);
  },
  putFlexibleWorkShift(shift) {
    const companyId = State.state.company.company_id;

    return axios
      .put(`${Config.apiBaseUrl}/flexible_work/shifts/${companyId}/${shift.shift_id}`, shift)
      .then(response => response.data, handleError);
  },
  postFlexibleWorkShiftsBulk(shifts) {
    const companyId = State.state.company.company_id;

    return axios
      .post(`${Config.apiBaseUrl}/flexible_work/shifts/${companyId}/bulk`, { shifts })
      .then(response => response.data, handleError);
  },
  getFlexibleWorkShiftClaims(shiftId) {
    const companyId = State.state.company.company_id;

    return axios
      .get(`${Config.apiBaseUrl}/flexible_work/claims/shift/${companyId}/${shiftId}`)
      .then(response => response.data, handleError);
  },
  getFlexibleWorkLocations() {
    const companyId = State.state.company.company_id;

    return axios
      .get(`${Config.apiBaseUrl}/flexible_work/locations/site/${companyId}`)
      .then(response => response.data, handleError);
  },
  getFlexibleWorkRoles() {
    const companyId = State.state.company.company_id;

    return axios
      .get(`${Config.apiBaseUrl}/flexible_work/roles/company/${companyId}`)
      .then(response => response.data, handleError);
  },
  deleteFlexibleWorkRole(role) {
    const companyId = State.state.company.company_id;

    return axios
      .delete(`${Config.apiBaseUrl}/flexible_work/roles/company/${companyId}/${role.role_id}`)
      .then(response => response.data, handleError);
  },
  postFlexibleWorkRole(role) {
    const companyId = State.state.company.company_id;

    return axios
      .post(`${Config.apiBaseUrl}/flexible_work/roles/company/${companyId}`, role)
      .then(response => response.data, handleError);
  },
  putFlexibleWorkRole(role) {
    const companyId = State.state.company.company_id;

    return axios
      .put(`${Config.apiBaseUrl}/flexible_work/roles/company/${companyId}/${role.role_id}`, role)
      .then(response => response.data, handleError);
  },
  getFlexibleWorkLocationProximityMetrics(locationId, distanceInMeters = DEFAULT_PROXIMATE_DISTANCE_IN_METERS) {
    const companyId = State.state.company.company_id;

    return axios
      .get(
        `${Config.apiBaseUrl}/flexible_work/metrics/${companyId}/location/${locationId}/proximity?distance=${distanceInMeters}`,
      )
      .then(response => response.data, handleError);
  },
  getFlexibleWorkShiftProximityMetrics(shiftId, distanceInMeters = DEFAULT_PROXIMATE_DISTANCE_IN_METERS) {
    const companyId = State.state.company.company_id;

    return axios
      .get(
        `${Config.apiBaseUrl}/flexible_work/metrics/${companyId}/shift/${shiftId}/proximity?distance=${distanceInMeters}`,
      )
      .then(response => response.data, handleError);
  },
  putFlexibleWorkUserSettings(employeeId, preferences) {
    const companyId = State.state.company.company_id;
    return axios
      .put(`${Config.apiBaseUrl}/flexible_work/settings/${companyId}/${employeeId}`, preferences)
      .then(response => response.data, handleError);
  },
  getFlexibleWorkUserSettings(params) {
    const companyId = State.state.company.company_id;
    return axios
      .get(`${Config.apiBaseUrl}/flexible_work/settings/${companyId}?${stringify(params)}`)
      .then(response => response.data, handleError);
  },
  postRateEmployee(employeeId, payload) {
    const companyId = State.state.company.company_id;
    return axios
      .post(`${Config.apiBaseUrl}/ratings/${companyId}/flexible-work/employee/${employeeId}`, payload)
      .then(response => response.data, handleError);
  },
  getFlexibleWorkCompanyOnboardingChecklist() {
    const companyId = State.state.company.company_id;
    return axios
      .get(`${Config.apiBaseUrl}/flexible_work/status/company/${companyId}/checklist`)
      .then(response => response.data, handleError);
  },
  getTalentPoolJobAd() {
    return axios
      .get(`${Config.apiBaseUrl}/talent_pool/onboarding/admin/jobs`)
      .then(response => response.data, handleError);
  },
  getTalentPoolShiftAd() {
    return axios
      .get(`${Config.apiBaseUrl}/talent_pool/onboarding/admin/shifts`)
      .then(response => response.data, handleError);
  },
  getTalentPoolLocations() {
    return axios.get(`${Config.apiBaseUrl}/talent_pool/locations`).then(response => response.data, handleError);
  },
  getTalentPoolPortalUsers() {
    const companyId = State.state.company.company_id;
    return axios
      .get(`${Config.apiBaseUrl}/talent_pool/portal_users/${companyId}`)
      .then(response => response.data, handleError);
  },
  addTalentPoolPortalUser(payload) {
    const companyId = State.state.company.company_id;
    return axios
      .post(`${Config.apiBaseUrl}/talent_pool/portal_users/${companyId}`, payload)
      .then(response => response.data, handleError);
  },
  deleteTalentPoolPortalUser(userId, roleId, entityId) {
    const companyId = State.state.company.company_id;
    const params = new URLSearchParams({ role_id: roleId, entity_id: entityId });
    return axios
      .delete(`${Config.apiBaseUrl}/talent_pool/portal_users/${companyId}/${userId}?${params.toString()}`)
      .then(response => response.data, handleError);
  },
  getTalentPoolMapEmployees(args) {
    return axios
      .get(`${Config.apiBaseUrl}/talent_pool/employees/geolocations?${new URLSearchParams(args).toString()}`)
      .then(response => response.data, handleError);
  },
  getTalentPoolLocationShiftFulfillmentSummary(locationId) {
    const companyId = State.state.company.company_id;
    return axios
      .get(`${Config.apiBaseUrl}/talent_pool/reports/${companyId}/shift_fulfillment_summary`, {
        params: { location_id: locationId },
      })
      .then(response => response.data, handleError);
  },
  getTalentPoolLocationRating(locationId) {
    return axios
      .get(`${Config.apiBaseUrl}/talent_pool/locations/${locationId}/ratings`)
      .then(response => response.data, handleError);
  },
  getTalentPoolShiftsByLocation(filters) {
    const companyId = State.state.company.company_id;

    const validFilters = omitBy(filters, f => isNull(f) || isUndefined(f));
    const params = new URLSearchParams(Object.entries(validFilters)).toString();
    return axios.get(`${Config.apiBaseUrl}/talent_pool/shifts_by_company/${companyId}?${params}`).then(response => {
      return {
        ...response.data,
        data: response.data.data.map(item => ({
          ...item,
          start_at: item.start_at + 'Z',
          end_at: item.end_at + 'Z',
        })),
      };
    }, handleError);
  },
  getTalentPoolShift(shiftId) {
    return axios.get(`${Config.apiBaseUrl}/talent_pool/shifts/${shiftId}`).then(response => {
      return {
        data: {
          ...response.data.data,
          start_at: response.data.data.start_at + 'Z',
          end_at: response.data.data.end_at + 'Z',
        },
      };
    }, handleError);
  },
  deleteTalentPoolShift(shiftId) {
    return axios
      .delete(`${Config.apiBaseUrl}/talent_pool/shifts/${shiftId}`)
      .then(response => response.data, handleError);
  },
  getTalentPoolShiftClockIn(claimId) {
    return axios
      .get(`${Config.apiBaseUrl}/talent_pool/claims/${claimId}/clock_in`)
      .then(response => response.data, handleError);
  },
  postTalentPoolShiftClockIn(claimId, payload) {
    const updatedPayload = {
      ...payload,
      actual_start_at: setDateTimeToUTC(payload.actual_start_at),
    };
    return axios
      .post(`${Config.apiBaseUrl}/talent_pool/claims/${claimId}/clock_in`, updatedPayload)
      .then(response => response.data, handleError);
  },
  flagImposter(claimId, payload) {
    return axios
      .post(`${Config.apiBaseUrl}/talent_pool/claims/${claimId}/failed_imposter_check`, payload)
      .then(response => response.data, handleError);
  },
  getTalentPoolShiftClaims(shiftId) {
    return axios
      .get(`${Config.apiBaseUrl}/talent_pool/claims_by_shift/${shiftId}`)
      .then(response => response.data, handleError);
  },
  getTalentPoolEntityDetails(entityId) {
    return axios
      .get(`${Config.apiBaseUrl}/talent_pool/organizations/${entityId}`)
      .then(response => response.data, handleError);
  },
  getTalentPoolSiteRoles(divisionId) {
    return axios
      .get(`${Config.apiBaseUrl}/talent_pool/divisions/${divisionId}/roles`)
      .then(response => response.data, handleError);
  },
  saveTalentPoolSiteRoleOverrides(divisionId, internalRoleId, payload) {
    return axios
      .patch(`${Config.apiBaseUrl}/talent_pool/divisions/${divisionId}/customise_role/${internalRoleId}`, payload)
      .then(response => response.data, handleError);
  },
  getTalentPoolRoles() {
    return axios.get(`${Config.apiBaseUrl}/talent_pool/roles`).then(response => response.data, handleError);
  },
  getPublicTalentPoolRoles() {
    return axios.get(`${Config.apiBaseUrl}/talent_pool/public/roles`).then(response => response.data, handleError);
  },
  postNoShow(claimId) {
    return axios
      .post(`${Config.apiBaseUrl}/talent_pool/claims/${claimId}/no_show`)
      .then(response => response.data, handleError);
  },
  getTalentPoolOpenPositions(locationId) {
    return axios
      .get(`${Config.apiBaseUrl}/talent_pool/open_positions/${locationId}`)
      .then(response => response.data, handleError);
  },
  getJobPostings(companyId) {
    return axios
      .get(`${Config.apiBaseUrl}/talent_pool/job_postings_by_company/${companyId}`)
      .then(response => response.data, handleError);
  },
  createJobPosting(payload) {
    return axios
      .post(`${Config.apiBaseUrl}/talent_pool/job_postings`, payload)
      .then(response => response.data, handleError);
  },
  updateJobPosting(jobId, payload) {
    return axios
      .patch(`${Config.apiBaseUrl}/talent_pool/job_postings/${jobId}`, payload)
      .then(response => response.data, handleError);
  },
  getJobPosting(jobId) {
    return axios
      .get(`${Config.apiBaseUrl}/talent_pool/job_postings/${jobId}`)
      .then(response => response.data, handleError);
  },
  deleteJobPosting(jobId) {
    return axios
      .delete(`${Config.apiBaseUrl}/talent_pool/job_postings/${jobId}`)
      .then(response => response.data, handleError);
  },
  getJobApplicants(jobId, args) {
    const params = new URLSearchParams(Object.entries(args)).toString();

    return axios
      .get(`${Config.apiBaseUrl}/talent_pool/job_postings/${jobId}/applicants?${params}`)
      .then(response => response.data, handleError);
  },
  approveJobApplicant(applicantId) {
    return axios
      .patch(`${Config.apiBaseUrl}/talent_pool/job_posting_applicants/${applicantId}/approve`)
      .then(response => response.data, handleError);
  },
  denyJobApplicant(applicantId) {
    return axios
      .patch(`${Config.apiBaseUrl}/talent_pool/job_posting_applicants/${applicantId}/deny`)
      .then(response => response.data, handleError);
  },
  patchCompany(entity) {
    return axios
      .patch(`${Config.apiBaseUrl}/talent_pool/organizations/company_details/${entity.entity_id}`, entity)
      .then(response => response.data, handleError);
  },
  postTalentPoolShift(shift) {
    const payload = {
      ...shift,
      start_at: setDateTimeToUTC(shift.start_at),
      end_at: setDateTimeToUTC(shift.end_at),
    };
    return axios.post(`${Config.apiBaseUrl}/talent_pool/shifts`, payload).then(response => response.data, handleError);
  },
  updateTalentPoolShift(shiftId, shift) {
    const payload = {
      ...shift,
      ...(shift.start_at ? { start_at: setDateTimeToUTC(shift.start_at) } : {}),
      ...(shift.end_at ? { end_at: setDateTimeToUTC(shift.end_at) } : {}),
    };
    return axios
      .patch(`${Config.apiBaseUrl}/talent_pool/shifts/${shiftId}`, payload)
      .then(response => response.data, handleError);
  },
  postBulkTalentPoolShifts(shifts) {
    const payload = shifts.map(shift => ({
      ...shift,
      start_at: setDateTimeToUTC(shift.start_at),
      end_at: setDateTimeToUTC(shift.end_at),
    }));
    return axios
      .post(`${Config.apiBaseUrl}/talent_pool/shifts/bulk`, payload)
      .then(response => response.data, handleError);
  },
  getTalentPoolOrganisation() {
    return axios.get(`${Config.apiBaseUrl}/talent_pool/organizations/`).then(response => response.data, handleError);
  },
  postOrganisationalGroup(group) {
    return axios
      .post(`${Config.apiBaseUrl}/organizational_groups/`, group)
      .then(response => response.data, handleError);
  },
  patchOrganisationalGroup(group) {
    return axios
      .patch(`${Config.apiBaseUrl}/organizational_groups/${group.entity_id}`, group)
      .then(response => response.data, handleError);
  },
  approveTalentPoolShiftClaim(claimId) {
    return axios
      .post(`${Config.apiBaseUrl}/talent_pool/claims/${claimId}/approve`)
      .then(response => response.data, handleError);
  },
  signOffTalentPoolShiftClaim(claimId, payload) {
    const updatedPayload = {
      ...payload,
      actual_start_at: setDateTimeToUTC(payload.actual_start_at),
      actual_end_at: setDateTimeToUTC(payload.actual_end_at),
    };
    return axios
      .post(`${Config.apiBaseUrl}/talent_pool/claims/${claimId}/fulfill`, updatedPayload)
      .then(response => response.data, handleError);
  },
  closeJob(jobId, payload) {
    return axios
      .post(`${Config.apiBaseUrl}/talent_pool/job_postings/${jobId}/close`, payload)
      .then(response => response.data, handleError);
  },
  addTalentPoolShiftClaimRating(claimId, shiftId, rating, properties = {}) {
    return axios
      .post(`${Config.apiBaseUrl}/talent_pool/claims/${claimId}/rating`, {
        shift_id: shiftId,
        rating,
        properties,
      })
      .then(response => response.data, handleError);
  },
  getCandidateRanking(shiftId) {
    return axios
      .get(`${Config.apiBaseUrl}/talent_pool/candidates/${shiftId}`)
      .then(response => response.data, handleError);
  },
  getShortlistedCandidates(jobPostingId) {
    return axios.get(`${Config.apiBaseUrl}/talent_pool/shortlist/${jobPostingId}`).then(response => {
      return {
        ...response.data,
        seal_at: response.data.seal_at + 'Z',
        sealed_at: response.data.sealed_at + 'Z',
        unlocked_at: response.data.unlocked_at + 'Z',
      };
    }, handleError);
  },
  getShortlistPaymentDetails(jobPostingId) {
    return axios
      .get(`${Config.apiBaseUrl}/talent_pool/shortlist/${jobPostingId}/payment-details`)
      .then(response => response.data, handleError);
  },
  unlockShortlist(jobPostingId) {
    return axios
      .post(`${Config.apiBaseUrl}/talent_pool/shortlist/${jobPostingId}/unlock`)
      .then(response => response.data, handleError);
  },
  getUnlockedShortlist(jobPostingId) {
    return axios
      .get(`${Config.apiBaseUrl}/talent_pool/shortlist/${jobPostingId}/unlock`)
      .then(response => response.data, handleError);
  },
  getTalentPoolExample(postcode, funktion, role) {
    return axios
      .post(`${Config.apiBaseUrl}/talent_pool/public/example`, { postcode: postcode, function: funktion, role: role })
      .then(response => response.data, handleError);
  },
  postTalentPoolTryOut(payload) {
    return axios
      .post(`${Config.apiBaseUrl}/talent_pool/public/tryout`, payload)
      .then(response => response.data, handleError);
  },
  getPublicShortlistedCandidates(jobPostingId) {
    return axios
      .get(`${Config.apiBaseUrl}/talent_pool/public/shortlist/${jobPostingId}`)
      .then(response => response.data, handleError);
  },
  getShortlistUpgradeIntent(jobPostingId) {
    return axios
      .post(`${Config.apiBaseUrl}/talent_pool/public/upgrade/${jobPostingId}`, {})
      .then(response => response.data, handleError);
  },
  getShortlistUpgradeCredentials(jobPostingId) {
    return axios
      .get(`${Config.apiBaseUrl}/talent_pool/public/upgrade/${jobPostingId}`)
      .then(response => response.data, handleError);
  },
  getEmployeeProfile(employeeId) {
    return axios
      .get(`${Config.apiBaseUrl}/talent_pool/profile/${employeeId}`)
      .then(response => response.data, handleError);
  },
  postAdminOnboarding() {
    return axios.post(`${Config.apiBaseUrl}/talent_pool/onboarding/admin`).then(response => response.data, handleError);
  },
  uploadImage(image) {
    return axios.post(`${Config.apiBaseUrl}/images/upload/`, image).then(response => response.data, handleError);
  },
  smeGetSalaryHistory(employeeId) {
    return axios.get(`${Config.apiBaseUrl}/sme/${employeeId}/history/salary`).then(response => {
      return response.data;
    }, handleError);
  },
  smeGetExpenseHistory(companyId) {
    return axios
      .get(`${Config.apiBaseUrl}/sme/${companyId}/expenses/history`)
      .then(response => response.data, handleError);
  },
  getAbsencesForCompany(params) {
    const companyId = State.state.company.company_id;
    return axios
      .get(`${Config.apiBaseUrl}/absences/company/${companyId}?${stringify(params)}`)
      .then(response => response.data, handleError);
  },
  postLumpSumSchedule(employeeId, payload) {
    return axios
      .post(`${Config.apiBaseUrl}/sme/${employeeId}/lump-sum`, payload)
      .then(response => response.data, handleError);
  },
  smeUploadExpenses(companyId, fileName, content, metadata) {
    metadata = { ...metadata, source: 'SME_PORTAL' };

    // Note: content should be instance of Uint8Array
    if (!(content instanceof Uint8Array)) {
      throw new Error('File content must be supplied as Uint8Array');
    }

    return axios
      .post(
        `${Config.apiBaseUrl}/sme/${companyId}/expenses/upload`,
        stringify({
          file: base64.fromByteArray(content),
          file_name: fileName,
          metadata: JSON.stringify(metadata),
        }),
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        },
      )
      .then(
        response => response.data,
        error => {
          if (error.response) {
            throw error.response.data;
          } else {
            throw error;
          }
        },
      );
  },
  getLastestBrowserUploads(params) {
    const companyId = State.state.user.company.company_id;
    return axios
      .get(`${Config.apiBaseUrl}/companies/integrations/${companyId}/latest-browser-upload-files?${stringify(params)}`)
      .then(response => response.data, handleError);
  },
};

export default ApiClient;
