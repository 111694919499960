<template>
  <div class="transfer-content">
    <div v-if="loading">
      <AppLoading></AppLoading>
    </div>
    <div v-else>
      <div class="table-section">
        <span class="table-title">Unfinalised</span>
        <b-table
          class="transfer-list"
          :fields="IN_PROGRESS_FIELDS"
          :items="unfinalisedTransfers"
          table-class="table-minimal"
          v-if="!!unfinalisedTransfers.length"
        >
          <template v-slot:cell(type)="data">
            <Users height="20" width="20" :color="PaletteColors['default-lighten-20']" v-if="data.item.type === 'bulk'">
            </Users>
            <User height="20" width="20" :color="PaletteColors['default-lighten-20']" v-else></User>
          </template>
          <template v-slot:cell(batch)="data">
            <span class="name">
              {{
                data.item.employee_name
                  ? `${data.item.batch} • ${data.item.employee_name}`
                  : `${data.item.batch} • ${data.item.number_of_payees} payee${
                      data.item.number_of_payees === 1 ? '' : 's'
                    }`
              }}
            </span>
          </template>
          <template v-slot:cell(amount)="data">
            {{ toCurrency(data.item.amount, 'USD') }}
          </template>
          <template v-slot:cell(goTo)="data">
            <div class="go-to" @click="viewBatch(data?.item)" :id="`see-more-${data?.item?.batch_number}`">
              <CaretRight />
            </div>
          </template>
        </b-table>
        <div v-else class="no-transfers">No unfinalised transfers</div>
      </div>
      <div class="table-section">
        <span class="table-title">Completed</span>
        <b-table
          v-if="!!transfers.length"
          class="transfer-list"
          :fields="COMPLETED_FIELDS"
          :items="transfers"
          table-class="table-minimal"
        >
          <template v-slot:cell(type)="data">
            <Users height="20" width="20" :color="PaletteColors['default-lighten-20']" v-if="data.item.type === 'bulk'">
            </Users>
            <User height="20" width="20" :color="PaletteColors['default-lighten-20']" v-else></User>
          </template>
          <template v-slot:cell(employee_name)="data">
            <span class="name">
              {{
                data.item.employee_name
                  ? `${data.item.batch} • ${data.item.employee_name}`
                  : `${data.item.batch} • ${data.item.number_of_payees} payee${
                      data.item.number_of_payees === 1 ? '' : 's'
                    }`
              }}
            </span>
          </template>
          <template v-slot:cell(reason)="data">
            <PaymentTypePill :reason="data.item.reason" :reason-options="paymentReasons" />
          </template>
          <template v-slot:cell(amount)="data">
            {{ toCurrency(data.item.amount, 'USD') }}
          </template>
          <template v-slot:cell(goTo)="data">
            <div class="go-to" @click="viewBatch(data?.item)" :id="`see-more-${data?.item?.batch}`">
              <CaretRight />
            </div>
          </template>
        </b-table>
        <div v-else class="no-transfers">No completed transfers</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Users from '@/assets/icons/Users.vue';
import User from '@/assets/icons/User.vue';
import { ref, watch } from 'vue';
import { PaletteColors } from '@/Theme';
import { format } from 'date-fns';
import CaretRight from '@/assets/icons/CaretRight.vue';
import { useRouter } from 'vue-router/composables';
import { useQuery } from '@tanstack/vue-query';
import { getOffCycleConfig, getOffCyclePayments } from '@/queries/one-time-payments';
import { toCurrency } from '@/utils';
import AppLoading from '@/components/AppLoading.vue';
import PaymentTypePill from '@/pages/one-time-payments/components/payment-type-pill.vue';

const transfers = ref([]);
const unfinalisedTransfers = ref([]);
const loading = ref(true);
const paymentReasons = ref([]);

const router = useRouter();

const getPaymentsQuery = useQuery(getOffCyclePayments());
const offCycleConfig = useQuery(getOffCycleConfig());

watch([getPaymentsQuery.fetchStatus, getPaymentsQuery.isPending], () => {
  loading.value = getPaymentsQuery.isFetching;
  if (getPaymentsQuery.data.value) {
    fetchAndMapTransferData();
  }
});

watch([offCycleConfig.fetchStatus, offCycleConfig.isPending], () => {
  if (offCycleConfig.data.value) {
    paymentReasons.value = offCycleConfig.data.value.supported_payment_types;
  }
});

const IN_PROGRESS_FIELDS = [
  {
    key: 'type',
    label: '',
    thStyle: { width: '30px' },
    tdStyle: { width: '30px' },
  },
  {
    key: 'batch',
    label: 'Batch',
  },
  {
    key: 'created_at',
    label: 'Created at',
    formatter: value => formatDateForTable(value),
  },
  {
    key: 'status',
    label: 'Status',
    tdClass: 'text-right',
    thStyle: { textAlign: 'right' },
  },
  {
    key: 'amount',
    label: 'Amount',
    tdClass: 'text-right',
    thStyle: { textAlign: 'right' },
  },
  {
    key: 'goTo',
    label: '',
    thStyle: { width: '20px' },
  },
];

const COMPLETED_FIELDS = [
  {
    key: 'type',
    label: '',
    thStyle: { width: '30px' },
    tdStyle: { width: '30px' },
  },
  {
    key: 'employee_name',
    label: 'Employee/Batch',
  },
  {
    key: 'submitted_at',
    label: 'Submitted at',
    formatter: value => formatDateForTable(value),
  },
  {
    key: 'reason',
    label: 'Reason',
    tdClass: 'd-flex justify-content-end',
    thStyle: { textAlign: 'right' },
  },
  {
    key: 'billing_type',
    label: 'Paid with',
    tdClass: 'text-right text-uppercase',
    thStyle: { textAlign: 'right' },
  },
  {
    key: 'status',
    label: 'Status',
    tdClass: 'text-right',
    thStyle: { textAlign: 'right' },
  },
  {
    key: 'amount',
    label: 'Amount',
    tdClass: 'text-right',
    thStyle: { textAlign: 'right' },
  },
  {
    key: 'goTo',
    label: '',
    thStyle: { width: '20px' },
    tdStyle: { width: '20px' },
  },
];

// Methods
const fetchAndMapTransferData = async () => {
  const data = getPaymentsQuery.data.value || [];

  const mappedTransferData = data.map(batch => ({
    ...batch,
    type: batch.properties?.upload_type || 'bulk',
    id: batch.off_cycle_payment_group_id,
    batch: `Batch #${batch.batch_number}`,
    employee_name: batch.full_name,
    amount: batch.sum,
  }));

  unfinalisedTransfers.value = mappedTransferData
    .filter(transfer => ['DRAFT', 'PROCESSING', 'PENDING'].includes(transfer.status))
    .sort((a, b) => b.batch_number - a.batch_number);
  transfers.value = mappedTransferData
    .filter(transfer => ['COMPLETE', 'PROCESSED'].includes(transfer.status))
    .sort((a, b) => b.batch_number - a.batch_number);
};

const formatDateForTable = date => {
  if (!date) {
    return '';
  }
  return format(new Date(date), 'MM/dd/yyyy HH:mm a');
};

const viewBatch = batch => {
  if (batch.properties.upload_type === 'bulk' && batch.status === 'DRAFT') {
    router.push({ name: 'one-time-payments-bulk', query: { batchId: batch.id } });
    return;
  }
  router.push({ name: 'one-time-payment', params: { batchId: batch.id } });
};
</script>

<style scoped>
.transfer-content {
  padding: 1rem 0;
  width: 100%;
}

.transfer-list {
  background-color: var(--palette-color-base-white);
  border-radius: 0.5rem;
  padding: 2rem;
  width: 100%;
}

.name {
  text-transform: capitalize;
}

.table-title {
  font-size: 13px;
  font-weight: 600;
  color: var(--palette-color-default-lighten-20);
  margin-left: 1rem;
  margin-top: 1rem;
}

.table-section {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.go-to {
  &:hover {
    cursor: pointer;

    svg {
      fill: var(--palette-color-brand-primary);
    }
  }
}

.no-transfers {
  padding: 1rem;
  text-align: center;
  color: var(--palette-color-default-lighten-20);
}
</style>
