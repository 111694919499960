<template>
  <aside :class="['outer', { 'outer--visible': visible }]">
    <div class="shadow" @click="onToggle"></div>
    <div class="inner">
      <div class="logo">
        <div>
          <img :src="themeImage('logo-white.svg')" :alt="`${partnershipName} Logo`" />
        </div>
        <font-awesome-icon class="aside-close" size="2x" icon="times" @click="onToggle" />
      </div>
      <template v-for="(el, index) of elements">
        <template v-if="el.type === ELEMENT_TYPE.LINK && !shouldHide(el)">
          <router-link
            @click.native="onClick(el)"
            :to="{ name: el.link ? el.link : '' }"
            :class="{ 'element-link': true, 'element-link-active': $route.name === el.link, [el.class || '']: true }"
            :key="index"
            :id="el.link"
          >
            <font-awesome-icon v-if="el.icon" :icon="el.icon" class="mr-3" fixed-width />
            <span>{{ el.text }}</span>
          </router-link>
        </template>
        <template v-if="el.type === ELEMENT_TYPE.DIVIDER && !shouldHide(el)">
          <hr class="element-divider" :key="index" />
        </template>
      </template>
      <div class="bottom">
        <img
          v-if="themeId"
          src="@/assets/powered-by-wagestream-white.svg"
          alt="Powered by Wagestream"
          class="powered-by"
        />
        <ReferralsAdvertisment v-else-if="shouldShowReferralsAdvertisment && !isDisablePortalReferrals" />
      </div>
    </div>
  </aside>
</template>

<script>
import { themeImage } from '@/Theme';
import useFeatureFlags from '@/composables/useFeatureFlags';
import State from '@/state/State';
import { getBankingInfo } from '@/Banking';
import { getPartnershipData } from '@/utils/partnership';
import ReferralsAdvertisment from '@/components/ReferralsAdvertisment.vue';

const ELEMENT_TYPE = {
  DIVIDER: 'DIVIDER',
  SPACER: 'SPACER',
  LINK: 'LINK',
};

export default {
  name: 'AppAside',
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    ReferralsAdvertisment,
  },
  data() {
    const { name: partnershipName, themeId } = getPartnershipData();
    const isFlexibleWorkRoute = this.$route.path.startsWith('/flexible-work');
    const bankingInfo = getBankingInfo();

    const showOffCycleStandalone = this.offCyclePaymentsVersion === 'v2-standalone';

    const state = State.state;
    const defaultPages = [
      {
        type: ELEMENT_TYPE.LINK,
        icon: ['fas', 'analytics'],
        link: 'sme-dashboard',
        text: 'Dashboard',
      },
      {
        type: ELEMENT_TYPE.LINK,
        icon: ['fad', 'chart-pie'],
        link: 'analytics-homepage',
        text: 'Analytics',
      },
      {
        type: ELEMENT_TYPE.LINK,
        icon: ['fad', 'info-circle'],
        link: 'help-center',
        text: 'Help Center',
        hide: !state.company.properties.portal?.can_view_help_center,
      },
      {
        type: ELEMENT_TYPE.LINK,
        icon: ['fad', 'donate'],
        link: 'funding-position',
        text: 'Funding Position',
        hide: !state.claims.a || this.isFundingPositionPageDisabled,
      },
      {
        type: ELEMENT_TYPE.DIVIDER,
      },
      {
        type: ELEMENT_TYPE.LINK,
        icon: ['fad', 'users'],
        link: 'employees',
        text: 'Employees',
      },

      {
        type: ELEMENT_TYPE.LINK,
        icon: ['fad', 'calendar-alt'],
        link: 'shifts',
        text: 'Shifts',
      },
      {
        type: ELEMENT_TYPE.LINK,
        icon: ['fad', 'user-clock'],
        link: 'absences',
        text: 'Absences',
        hide: !state.claims.a || !this.canViewAbsences,
      },
      {
        type: ELEMENT_TYPE.LINK,
        icon: ['fad', 'money-check-alt'],
        link: 'sme-pay-day',
        text: 'Pay',
        hide: !state.claims.a,
      },
      {
        type: ELEMENT_TYPE.LINK,
        icon: ['fad', 'file-invoice-dollar'],
        link: 'last-day-pay',
        text: 'Last Day Pay Report',
        hide: this.offCyclePaymentsVersion === 'off' ? !this.isEnableLastDayPay : true,
      },
      {
        type: ELEMENT_TYPE.LINK,
        icon: ['fad', 'exchange'],
        link: 'streams',
        text: `${bankingInfo.stream.pastTense.charAt(0).toUpperCase() + bankingInfo.stream.pastTense.slice(1)} Wages`,
        hide: !this.isEnableStreamedWagesPage,
      },
      {
        type: ELEMENT_TYPE.LINK,
        icon: ['far', 'credit-card'],
        link: 'employee-payments',
        text: 'Employee Payments',
        hide: !this.isEnableEmployeePaymentsPage,
      },
      {
        type: ELEMENT_TYPE.LINK,
        icon: ['fas', 'rotate'],
        link: 'one-time-payments',
        text: 'Off-cycle Payments',
        hide: this.offCyclePaymentsVersion !== 'v2',
      },
      {
        type: ELEMENT_TYPE.DIVIDER,
      },
      {
        type: ELEMENT_TYPE.LINK,
        icon: ['fad', 'university'],
        link: 'sme-funds',
        text: 'Funds',
        hide: () => !this.isSelfFunded,
      },
      {
        type: ELEMENT_TYPE.LINK,
        icon: ['fad', 'users-cog'],
        link: 'admins',
        text: 'Admins',
        hide: !state.claims.a || !state.canAdminAdmins,
      },
      {
        type: ELEMENT_TYPE.LINK,
        icon: ['fad', 'sitemap'],
        link: 'divisions',
        text: 'Divisions',
        hide: !state.claims.a || !state.canAdminAdmins,
      },
      {
        type: ELEMENT_TYPE.LINK,
        icon: ['fad', 'chart-network'],
        link: 'sme-integrations',
        text: 'Integrations',
        hide: !state.claims.a || !state.canAdminAdmins,
      },
      {
        type: ELEMENT_TYPE.LINK,
        icon: ['fad', 'receipt'],
        link: 'sme-subscription',
        text: 'Invoices',
        hide: this.hideInvoicePage ? true : !state.claims.a || !state.canAdminAdmins,
      },
      {
        type: ELEMENT_TYPE.LINK,
        icon: ['fad', 'cogs'],
        link: 'company-settings',
        text: 'Settings',
        hide: !state.claims.a,
      },
      {
        type: ELEMENT_TYPE.LINK,
        icon: ['fad', 'upload'],
        link: 'secure-file-transfer',
        text: 'Secure File Transfer',
        hide: !state.claims.a || !state?.company?.properties?.portal?.show_file_upload,
      },
      {
        type: ELEMENT_TYPE.LINK,
        icon: ['far', 'clock'],
        link: 'recent-file-uploads',
        text: 'Recent Uploads',
        hide: !state.claims.a || !state?.company?.properties?.portal?.show_recent_file_uploads,
      },
    ];
    const flexibleWorkPages = [
      {
        type: ELEMENT_TYPE.LINK,
        icon: ['fad', 'calendar-alt'],
        link: 'flexible-work',
        text: 'Dashboard',
      },
      {
        type: ELEMENT_TYPE.LINK,
        icon: ['fal', 'cog'],
        link: 'flexible-work-roles-and-locations',
        text: 'Roles & Locations',
        hide: !state.claims?.a,
      },
      {
        type: ELEMENT_TYPE.LINK,
        icon: ['fal', 'ban'],
        link: 'flexible-work-blocked-employees',
        text: 'Block list',
      },
    ];

    if (showOffCycleStandalone) {
      const pages = [
        {
          type: ELEMENT_TYPE.LINK,
          icon: ['fad', 'chart-pie'],
          link: 'analytics-homepage',
          text: 'Analytics',
        },
        {
          type: ELEMENT_TYPE.LINK,
          icon: ['fas', 'rotate'],
          link: 'one-time-payments',
          text: 'Off-cycle Payments',
        },
      ];
      return {
        ELEMENT_TYPE,
        partnershipName,
        themeId,
        elements: pages,
      };
    }
    const pages = (isFlexibleWorkRoute ? flexibleWorkPages : defaultPages).concat({
      type: ELEMENT_TYPE.SPACER,
    });
    return {
      ELEMENT_TYPE,
      partnershipName,
      themeId,
      elements: pages,
    };
  },
  setup() {
    const {
      isEnableStreamedWagesPage,
      isEnableEmployeePaymentsPage,
      isFundingPositionPageDisabled,
      canIntegrate,
      isSelfFunded,
      isSubscription,
      canViewIntegrationHistory,
      canViewAbsences,
      shouldShowReferralsAdvertisment,
      isEnableLastDayPay,
      isDisablePortalReferrals,
      offCyclePaymentsVersion,
      isEnableSmeLaunchV2,
      hideInvoicePage,
    } = useFeatureFlags();

    return {
      isEnableStreamedWagesPage,
      isEnableEmployeePaymentsPage,
      isFundingPositionPageDisabled,
      canIntegrate,
      isSelfFunded,
      isSubscription,
      canViewIntegrationHistory,
      canViewAbsences,
      shouldShowReferralsAdvertisment,
      isEnableLastDayPay,
      isDisablePortalReferrals,
      offCyclePaymentsVersion,
      isEnableSmeLaunchV2,
      hideInvoicePage,
    };
  },
  methods: {
    themeImage,
    shouldHide(el) {
      if (!el.hide) {
        if (this.isEnableSmeLaunchV2) {
          if (!['Dashboard', 'Analytics', 'Invoices'].includes(el.text) || !el.text) {
            return true;
          }
        }
        return false;
      }
      if (typeof el.hide === 'boolean') {
        return el.hide;
      } else {
        return el.hide();
      }
    },
    onClick(el) {
      if (el.click) {
        el.click();
      }
    },
    onToggle() {
      this.$emit('toggle');
    },
  },
};
</script>

<style scoped lang="scss">
.outer {
  position: sticky;
  background: var(--palette-color-brand-primary) !important;
  color: var(--palette-color-base-white);
  min-width: 250px;
  max-width: 250px;
  height: 100%;
}

.inner {
  display: flex;
  position: sticky;
  box-sizing: content-box;
  flex-direction: column;
  background: var(--palette-color-brand-primary);
  overflow: auto;
  top: 0;
  max-height: 100vh;
  height: 100%;
}

.logo {
  align-items: center;
  display: flex;
  flex-shrink: 0;
  height: 5.25rem;
  justify-content: center;
  width: 100%;
}

.logo img {
  display: block;
  height: 2.5rem;
  width: auto;
}

.logo .aside-close {
  display: none;
  position: absolute;
  cursor: pointer;
  color: var(--palette-color-base-white);
  right: 16px;
  top: 19px;
}

.element-link {
  display: flex;
  position: relative;
  padding: 0.8rem 1.5rem;
  color: var(--palette-color-base-white);
  font-size: 14px;

  @media (max-height: 950px) {
    font-size: 14px;
    padding: 0.6rem 1.5rem;
  }

  @media (max-height: 825px) {
    font-size: 13px;
    padding: 0.5rem 1.5rem;
  }
}

.element-link-active,
.element-link:hover {
  background: var(--palette-color-brand-primary-lighten-20);
}

.element-link-active:before {
  display: block;
  position: absolute;
  background: var(--palette-color-core-5);
  content: '';
  height: 100%;
  width: 4px;
  left: 0;
  top: 0;
}

.element-link svg {
  font-size: 17.5px;
}

.element-divider {
  border-top: 0;
  margin: 15px;
  border: 1px solid var(--palette-color-base-white);
  opacity: 0.1;
}

.element-spacer {
  height: 100%;
}

.shadow {
  display: none;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  cursor: pointer;
  top: 0;
  left: 250px;
  height: 200vh;
  width: 100vh;
}

.powered-by {
  margin: 1.5rem auto;
  max-width: 6.25rem;
}

@media (max-width: 768px) {
  .outer {
    animation-fill-mode: forwards;
    transform: translateX(0);
    transition: opacity 0.25s, transform 0.25s;
    position: fixed;
    z-index: 1001;
    opacity: 1;
  }

  .outer:not(.outer--visible) {
    pointer-events: none;
    transform: translateX(-100%);
    opacity: 0;
  }

  .shadow,
  .aside-close {
    display: block !important;
  }

  .element-link {
    font-weight: 300;
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .outer:not(.outer--visible) {
    display: none;
  }
}

.bottom {
  display: flex;
  height: 100%;
  align-items: flex-end;
  justify-content: center;
  margin-top: 2rem;
}
</style>
