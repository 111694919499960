<template>
  <app-page
    title="Secure File Transfer"
    subtitle="Drop a file below to securely transfer it to your Client Success representative."
    :error="error"
    :icon="['fal', 'upload']"
  >
    <sme-alert level="info">Securely upload a file to Wagestream</sme-alert>
    <ValidationObserver>
      <sftp-bulk-upload @error="onError" :postFunction="postFile" :genericUpload="true"></sftp-bulk-upload>
    </ValidationObserver>
  </app-page>
</template>

<script>
import ApiClient from '@/ApiClient';
import AppPage from '@/components/AppPage.vue';
import SftpBulkUpload from '@/pages/SftpBulkUpload/components/SftpBulkUpload.vue';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import { ValidationObserver } from 'vee-validate';
import State from '@/state/State';

export default {
  name: 'SecureFileTransfer',
  components: { AppPage, SftpBulkUpload, SmeAlert, ValidationObserver },
  data() {
    return {
      error: undefined,
      date: undefined,
      state: State.state,
    };
  },
  methods: {
    onError(error) {
      this.error = error;
    },
  },
  computed: {
    postFile() {
      return ApiClient.postClientUpload;
    },
  },
};
</script>
