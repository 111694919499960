<template>
  <div>
    <div v-if="whatsapp && isEnableWhatsApp" class="modal">
      <dashboard-whats-app-modal @accept="handleLaunch" />
    </div>
    <dashboard-salary-sign-off-modal
      v-if="salarySignOffModal"
      class="modal"
      @confirm="salarySignOff"
      @reset="toggleSalarySignOffModal"
      :salariedEmployees="salariedEmployeesCount"
    />
    <div class="todos-list">
      <sme-box
        v-for="(todo, index) in mappedTodos"
        :key="`${todo.action}${index}`"
        :data-id="`dashboard.todos.todo.${todo.action}`"
        class="todos-list__item"
        compact
      >
        <p class="todos-list__item-description">
          <strong>{{ todo.description }}</strong>
        </p>
        <aside v-if="todo.buttons?.length" class="todos-list__item-aside">
          <b-button
            v-for="button in todo.buttons"
            :key="button.buttonLabel"
            :variant="button.variant || 'primary'"
            class="todos-list__item-button"
            :disabled="todo.disabled || button.disabled"
            @click="button.callback"
          >
            {{ button.buttonLabel }}
          </b-button>
        </aside>
      </sme-box>
    </div>
  </div>
</template>

<script setup>
import orderBy from 'lodash/orderBy';
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router/composables';
import ApiClient from '@/ApiClient';
import SmeBox from '@/components/atoms/SmeBox';
import useFeatureFlags from '@/composables/useFeatureFlags';
import DashboardSalarySignOffModal from '@/pages/dashboard/components/DashboardSalarySignOffModal.vue';
import DashboardWhatsAppModal from '@/pages/dashboard/components/DashboardWhatsAppModal.vue';
import State from '@/state/State';
import useEmployees from '@/state/composables/useEmployees';
import useLaunchState from '@/state/composables/useLaunchState';
import usePayschedules from '@/state/composables/usePayschedules';
import { TODO_ACTIONS } from '@/utils/Todo';
import { toTitleCase, pluralize } from '@/utils/common';
import useAnalytics from '@/state/composables/useAnalytics';

const $router = useRouter();
const { employeesCount, reEnrollingEmployees, salariedEmployeesCount } = useEmployees();
const { payschedules } = usePayschedules();
const { outstandingTodos } = useLaunchState();
const { isEnableWhatsApp, isSelfFunded } = useFeatureFlags();
const { postAnalyticsEvent } = useAnalytics();

const performActionWithAnalytics = (task, callback) => {
  return () => {
    postAnalyticsEvent({
      intent: `navigate_task_list_${task}`,
      task: task,
      action: 'click',
    });
    callback();
  };
};

const METADATA = {
  [TODO_ACTIONS.SETUP_EMPLOYEE]: todo => ({
    action: TODO_ACTIONS.SETUP_EMPLOYEE,
    description: getSetupDescription(todo),
    buttons: [
      {
        buttonLabel: getSetupButtonLabel(todo),
        callback: () => $router.push({ name: 'employee-setup', params: { employeeId: todo.fk } }),
      },
      {
        buttonLabel: 'Opt out',
        variant: 'outline-primary',
        callback: () => $router.push({ name: 'employee-opt-out', params: { employeeId: todo.fk } }),
      },
    ],
  }),
  [TODO_ACTIONS.CHECK_EMPLOYEE_BANKING_MISMATCH]: todo => ({
    action: TODO_ACTIONS.CHECK_EMPLOYEE_BANKING_MISMATCH,
    description: `${todo.desc} banking details do not match.`,
    buttons: [
      {
        buttonLabel: 'Update banking',
        callback: () => $router.push({ name: 'employee-banking-mismatch-update', params: { employeeId: todo.fk } }),
      },
    ],
  }),
  [TODO_ACTIONS.DISABLE_EMPLOYEE]: todo => ({
    action: TODO_ACTIONS.DISABLE_EMPLOYEE,
    description: `${todo.desc} wants to opt out.`,
    buttons: [
      {
        buttonLabel: 'Complete opt-out',
        callback: () => $router.push({ name: 'employee-revert-banking', params: { employeeId: todo.fk } }),
      },
    ],
  }),
  [TODO_ACTIONS.SUBSCRIPTION_SETUP]: () => ({
    action: TODO_ACTIONS.SUBSCRIPTION_SETUP,
    description: 'Provide payment details for your chosen plan.',
    priority: 4,
    buttons: [
      {
        buttonLabel: 'Subscribe',
        callback: performActionWithAnalytics('add_subscription', () => $router.push({ name: 'sme-subscription' })),
      },
    ],
  }),
  [TODO_ACTIONS.SUBSCRIPTION_PENDING]: todo => ({
    action: TODO_ACTIONS.SUBSCRIPTION_PENDING,
    description:
      todo.desc || 'You have subscribed. We are waiting for confirmation that your subscription has been successful.',
    priority: 1,
    buttons: [
      {
        buttonLabel: 'View subscription',
        callback: performActionWithAnalytics('add_subscription', () => $router.push({ name: 'sme-subscription' })),
      },
    ],
  }),
  [TODO_ACTIONS.KYC_PENDING]: () => ({
    action: TODO_ACTIONS.KYC_PENDING,
    description: 'Verify company ownership to satisfy banking regulations.',
    priority: 1,
    buttons: [
      {
        buttonLabel: 'Start KYC',
        callback: performActionWithAnalytics('add_kyc', () => $router.push({ name: 'sme-verify-ownership' })),
      },
    ],
  }),
  [TODO_ACTIONS.FUND_PENDING]: () => ({
    action: TODO_ACTIONS.FUND_PENDING,
    description: 'Transfer funds to your deposit account.',
    depends: [TODO_ACTIONS.KYC_PENDING],
    priority: 1,
    buttons: [
      {
        buttonLabel: 'Transfer funds',
        callback: performActionWithAnalytics('add_funds', () => $router.push({ name: 'sme-funds' })),
      },
    ],
  }),
  [TODO_ACTIONS.ADD_EMPLOYEES]: () => ({
    action: TODO_ACTIONS.ADD_EMPLOYEES,
    description: 'Add your employees.',
    depends: [TODO_ACTIONS.CONFIGURE_PAYSCHEDULE],
    priority: 2,
    buttons: [
      {
        buttonLabel: 'Upload employees',
        callback: performActionWithAnalytics('add_employees', () => $router.push({ name: 'sme-employees-add' })),
      },
    ],
  }),
  [TODO_ACTIONS.CONFIGURE_INTEGRATION]: () => ({
    action: TODO_ACTIONS.CONFIGURE_INTEGRATION,
    description: 'Integrate with your rota provider.',
    depends: [TODO_ACTIONS.CONFIGURE_PAYSCHEDULE],
    priority: 2,
    buttons: [
      {
        buttonLabel: 'Configure integration',
        callback: performActionWithAnalytics('add_integration', () => $router.push({ name: 'sme-integrations' })),
      },
    ],
  }),
  [TODO_ACTIONS.CONFIGURE_PAYSCHEDULE]: () => ({
    action: TODO_ACTIONS.CONFIGURE_PAYSCHEDULE,
    description: 'Add a pay schedule.',
    priority: 3,
    buttons: [
      {
        buttonLabel: 'Add payschedule',
        callback: performActionWithAnalytics('add_pay_schedule', () =>
          $router.push({ name: 'pay-schedule', params: { payScheduleId: 'create' } }),
        ),
      },
    ],
  }),
  [TODO_ACTIONS.UPDATE_PAYSCHEDULE]: todo => ({
    action: TODO_ACTIONS.UPDATE_PAYSCHEDULE,
    description: 'Update your pay schedule.',
    buttons: [
      {
        buttonLabel: 'Update payschedule',
        callback: performActionWithAnalytics('update_pay_schedule', () =>
          $router.push({ name: 'pay-schedule', params: { payScheduleId: todo.fk } }),
        ),
      },
    ],
  }),
  [TODO_ACTIONS.SEND_INVITES]: todo => ({
    action: TODO_ACTIONS.SEND_INVITES,
    description: `Invite your ${employeesCount.value} employees.`,
    disabled: todo.disabled || true,
    priority: -1,
    buttons: [
      {
        buttonLabel: 'Send Invites',
        callback: isEnableWhatsApp
          ? performActionWithAnalytics('launch_company', askForWhatsappPermission)
          : performActionWithAnalytics('launch_company', launchCompany),
      },
    ],
  }),
  [TODO_ACTIONS.SALARY_SIGN_OFF]: () => ({
    action: TODO_ACTIONS.SALARY_SIGN_OFF,
    description: `Sign off salary accrual for your ${salariedEmployeesCount.value} salaried ${pluralize(
      'employee',
      salariedEmployeesCount.value,
    )}.`,
    buttons: [
      {
        buttonLabel: 'Confirm',
        callback: toggleSalarySignOffModal,
      },
    ],
  }),
  [TODO_ACTIONS.CONFIRM_UPCOMING_PAYSCHEDULE]: () => ({
    action: TODO_ACTIONS.CONFIRM_UPCOMING_PAYSCHEDULE,
    description: 'You have upcoming pay schedules',
    priority: -1,
    buttons: [
      {
        buttonLabel: 'Review payschedules',
        callback: () => $router.push({ name: 'sme-pay-day' }),
      },
    ],
  }),
  [TODO_ACTIONS.REVIEW_UPDATED_SUBPROCESSOR_LIST]: () => ({
    action: TODO_ACTIONS.REVIEW_UPDATED_SUBPROCESSOR_LIST,
    description: 'We have updated our subprocessor list',
    priority: -1,
    buttons: [
      {
        buttonLabel: 'Review',
        callback: () => {
          handleReviewSubprocessorListClick();
          $router.push({ name: 'company-settings' });
        },
      },
    ],
  }),
};

const whatsapp = ref(false);
const salarySignOffModal = ref(false);

const isAnyPayPeriodStartWithin7Days = computed(() => {
  const today = new Date();
  return payschedules.value.some(ps => {
    const payPeriodStartDate = new Date(ps.pay_period_start);
    const differenceInTime = payPeriodStartDate.getTime() - today.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    return differenceInDays <= 7 && differenceInDays >= 0;
  });
});

const mappedTodos = computed(() => {
  const todos = outstandingTodos.value.map(todo => METADATA[todo.action]?.(todo)).filter(todo => !!todo);
  if (isAnyPayPeriodStartWithin7Days.value) {
    todos.push(METADATA[TODO_ACTIONS.CONFIRM_UPCOMING_PAYSCHEDULE]());
  }
  todos.forEach(todo => (todo.disabled = todo.disabled || todos.some(({ action }) => todo.depends?.includes(action))));
  return orderBy(todos, todo => todo.priority || 0, ['desc']);
});

const askForWhatsappPermission = async () => {
  // only send to funded companies since we talk about vouchers

  if (!isSelfFunded.value) {
    whatsapp.value = true;
  } else {
    whatsapp.value = false;

    await handleLaunch({
      optIn: false,
      agreementId: undefined,
    });
  }
};

const salarySignOff = async () => {
  const signOffTodo = outstandingTodos.value.find(todo => todo.action === TODO_ACTIONS.SALARY_SIGN_OFF);
  const salarySignOffActionId = signOffTodo.action_id;
  await ApiClient.bulkSalarySignOff(salarySignOffActionId);
};

const toggleSalarySignOffModal = () => {
  salarySignOffModal.value = !salarySignOffModal.value;
};

const handleLaunch = async ({ optIn, agreementId }) => {
  await ApiClient.smePostWhatsAppOptIn(optIn, State.state.user.user_id, agreementId);
  await launchCompany();
};

const launchCompany = async () => {
  await ApiClient.launchCompany(State.state.company.company_id);
  postAnalyticsEvent({
    intent: `complete_task_list_launch`,
    task: 'launch_company',
    action: 'click',
  });
  window.location.reload();
};

const handleReviewSubprocessorListClick = () => {
  window.localStorage.setItem('has-seen-updated-subprocessor-list', new Date().toISOString());
};

const getSetupButtonLabel = todo => {
  return reEnrollingEmployees.value.find(employee => employee.employee_id === todo.fk) ? 'Re-enroll' : 'Enroll';
};

const getSetupDescription = todo => {
  return reEnrollingEmployees.value.find(employee => employee.employee_id === todo.fk)
    ? `${toTitleCase(todo.desc)} was enrolled with invalid bank details, please try again.`
    : `${toTitleCase(todo.desc)} wants to enroll.`;
};
</script>

<style lang="scss" scoped>
@use 'sass:math';

.todos-list {
  max-height: 25rem;
  overflow-y: auto;
}

.todos-list__item {
  @media (min-width: 768px) {
    align-items: center;
    display: flex;
  }
}

.todos-list__item-description {
  margin: 0;

  @media (min-width: 768px) {
    flex-basis: math.percentage(math.div(3, 5));
    margin: 0 1rem 0 0;
  }
}

.todos-list__item-aside {
  display: flex;
  margin-top: 0.5rem;

  @media (min-width: 768px) {
    flex-basis: math.percentage(math.div(2, 5));
    margin: 0;
    min-width: 10rem;
  }

  @media (min-width: 1140px) {
    min-width: 12.5rem;
  }
}

.todos-list__item-button {
  flex: 1;
  padding-left: 0;
  padding-right: 0;
  white-space: nowrap;

  & + & {
    margin-left: 1rem;
  }

  @media (max-width: 768px) {
    &:not(:only-child) {
      &:first-child {
        flex-basis: math.percentage(math.div(2, 3));
      }

      &:last-child {
        flex-basis: math.percentage(math.div(1, 3));
      }
    }
  }
}
</style>
