<template>
  <section class="sme-detailed-table">
    <b-table
      v-if="!loading"
      v-bind="attrs"
      v-on="listeners"
      class="mb-0 sme-detailed-table__wrapper"
      thead-class="d-none"
      responsive
      show-empty
    >
      <template v-for="(index, name) in $scopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data"></slot>
      </template>
      <template #cell()="data">
        <div class="sme-detailed-table__cell">
          <span class="sme-detailed-table__label">{{ data.field.label }}</span>
          <strong class="sme-detailed-table__value">{{ data.value }}</strong>
        </div>
      </template>
    </b-table>
    <app-loading v-if="typeof loading == 'boolean'" :loading="loading" />
  </section>
</template>

<script>
export default {
  inheritAttrs: false,
};
</script>

<script setup>
import { useAttrs, useListeners } from 'vue';
import AppLoading from '@/components/AppLoading.vue';

const attrs = useAttrs();
const listeners = useListeners();

defineProps({
  loading: Boolean,
});
</script>

<style lang="scss" scoped>
@use 'sass:color';

$row-variants: (
  danger: var(--palette-color-danger-lighten-90),
  warning: var(--palette-color-warning-lighten-90),
);

:deep {
  tbody {
    tr {
      &:first-child {
        th,
        td {
          border-top: 0;
        }
      }
    }
  }

  th,
  td {
    height: 1px;
    padding: 0.8rem 1rem !important;
    vertical-align: top !important;
  }

  .sme-detailed-table__wrapper {
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--palette-color-default-lighten-80);
      border-radius: 8px;
    }

    &::-webkit-scrollbar-track {
      background: var(--palette-color-default-lighten-95);
      border-radius: 8px;
    }
  }

  .sme-detailed-table__row--link {
    cursor: pointer;

    &:hover {
      td:first-child {
        .sme-detailed-table__label--large {
          color: var(--palette-color-brand-primary);
        }
      }
    }
  }

  @each $variant, $background-color in $row-variants {
    .sme-detailed-table__row--#{$variant} {
      background-color: $background-color !important;
    }
  }

  .sme-detailed-table__cell {
    font-size: 16px;
    min-width: 6rem;
  }

  .sme-detailed-table__cell--center {
    align-items: center;
    display: flex;
    height: 100%;
    min-width: none;
  }

  .sme-detailed-table__cell--center-column {
    flex-direction: column;
  }

  .sme-detailed-table__cell--end {
    justify-content: flex-end;
  }

  .sme-detailed-table__cell--wide {
    min-width: 10rem;
  }

  .sme-detailed-table__cell--ctas {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    min-height: 100%;
  }

  .sme-detailed-table__label {
    color: var(--palette-color-default-lighten-30);
    display: inline-block;
    font-size: 12px;
    margin-bottom: 0.25rem;
    white-space: nowrap;
    vertical-align: top !important;

    &[href]:hover {
      color: var(--palette-color-brand-primary);
    }
  }

  .sme-detailed-table__label--top-margin {
    margin-top: 0.75rem;
    margin-bottom: 0.1rem;
  }

  .sme-detailed-table__label--large {
    color: var(--palette-color-default);
    font-size: 18px;
    line-height: 1.2;
    white-space: normal;

    &:not(:last-child) {
      margin-bottom: 0.4rem;
    }
  }

  .sme-detailed-table__value {
    color: var(--palette-color-default);
    display: block;

    &[href]:hover {
      color: var(--palette-color-brand-primary);
    }
  }

  .sme-detailed-table__value--small {
    color: var(--palette-color-default-lighten-30);
    font-size: 14px;
  }

  .sme-detailed-table__value--primary {
    color: var(--palette-color-brand-primary);
  }

  .sme-detailed-table__cta {
    width: 7.5rem;
  }

  .sme-detailed-table__footnote {
    font-size: 14px;
  }
}
</style>
