<template>
  <app-page
    :title="`Batch #${batch?.batch_number || ''}`"
    subtitle="View details about this batch"
    icon="rotate"
    back-route="/one-time-payments"
  >
    <div v-if="batch">
      <div class="batch-details">
        <p><strong>Status:</strong> {{ getStatus(batch.status) }}</p>
        <p><strong>Created at:</strong> {{ new Date(batch.created_at).toLocaleString() }}</p>
        <p v-if="batch.submitted_at">
          <strong>Submitted at:</strong>
          {{ batch.submitted_at ? new Date(batch.submitted_at).toLocaleString() : 'Not yet submitted' }}
        </p>
        <h4 class="mt-3">Billing</h4>
        <p>
          Your billing method is via {{ batch.billing_type.toLowerCase() }}.
          <span v-if="batch.billing_type.toLowerCase() === 'ACH'">
            Your ACH account ends in {{ batch.ach_account_number.slice(-4) }}
          </span>
          <span v-if="batch.billing_type.toLowerCase() === 'invoice' && isCompleted">
            You can now view, download and settle your invoice.
            <SmeButtonLink :no-underline="downloadInvoiceMutation.isPending.value" @click="downloadInvoice">{{
              downloadInvoiceMutation.isPending.value ? 'Downloading ...' : 'Download your invoice now'
            }}</SmeButtonLink>
          </span>
        </p>
        <div class="mt-3">
          <h3>Payments</h3>
          <div v-if="batch.payments.length !== 0">
            <b-table :items="batch.payments" :fields="FIELDS">
              <template v-slot:cell(payment_type)="data">
                <PaymentTypePill :reason="data.item.payment_type" :reason-options="paymentReasons" />
              </template>
            </b-table>
          </div>
          <div v-else>No payments in this batch</div>
        </div>
      </div>
    </div>
    <div v-else>Loading...</div>
  </app-page>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import AppPage from '@/components/AppPage.vue';
import { useRoute } from 'vue-router/composables';
import { toCurrency } from '@/utils';
import PaymentTypePill from '@/pages/one-time-payments/components/payment-type-pill.vue';
import { useMutation, useQuery } from '@tanstack/vue-query';
import { downloadOffCycleBatchInvoice, getOffCycleConfig, getOffCyclePayment } from '@/queries/one-time-payments';
import SmeButtonLink from '@/components/atoms/SmeButtonLink.vue';

const batch = ref(null);
const route = useRoute();
const batchId = ref(route.params.batchId);
const paymentReasons = ref([]);

const getPaymentQuery = useQuery(getOffCyclePayment(batchId));
const offCycleConfig = useQuery(getOffCycleConfig());
const downloadInvoiceMutation = useMutation(downloadOffCycleBatchInvoice());

const isCompleted = computed(() => {
  if (!batch.value) {
    return false;
  }
  return ['COMPLETE', 'PROCESSED'].includes(batch.value.status);
});

const getStatus = status => {
  if (status === 'PROCESSED') {
    return 'PAYMENT MADE';
  }

  if (status === 'COMPLETE') {
    return 'PAYMENT MADE';
  }

  if (status === 'FAILED') {
    return 'PAYMENT FAILED';
  }

  if (status === 'PENDING') {
    return 'PAYMENT SENT';
  }

  return status;
};

watch([getPaymentQuery.fetchStatus, getPaymentQuery.isPending], () => {
  if (getPaymentQuery.data.value) {
    batch.value = getPaymentQuery.data.value;
  }
});

watch([offCycleConfig.fetchStatus, offCycleConfig.isPending], () => {
  if (offCycleConfig.data.value) {
    paymentReasons.value = offCycleConfig.data.value.supported_payment_types;
  }
});

const FIELDS = [
  { key: 'employee_code', label: 'Employee Code' },
  { key: 'employee_email', label: 'Email' },
  {
    key: 'transaction_properties.beneficiary_account_number',
    label: 'Account number',
    formatter: value => '****' + value?.slice(-4),
  },
  { key: 'transaction_properties.beneficiary_sort_code', label: 'Routing number' },
  { key: 'transaction_properties.pay_type', label: 'Payment method' },
  { key: 'payment_rail_used', label: 'Status', formatter: value => (value ? 'PAYMENT MADE' : '') },
  { key: 'payment_type', label: 'Reason' },
  { key: 'amount', label: 'Amount', formatter: value => toCurrency(value, 'USD') },
];

const downloadInvoice = () => {
  if (downloadInvoiceMutation.isPending.value) {
    return;
  }

  downloadInvoiceMutation.mutate(batchId.value, {
    onSuccess: async response => {
      const blob = new Blob([response], { type: 'application/pdf' });

      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.setAttribute('download', `invoice_${batchId.value}.pdf`);
      document.body.append(link);
      link.click();
      link.remove();
    },
    onError: error => {
      console.error('Error downloading invoice:', error);
    },
  });
};
</script>

<style scoped>
.payment-item::marker {
  content: '';
}
</style>
