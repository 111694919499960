<template>
  <div>
    <b-row>
      <b-col :lg="lg" sm="6" cols="12" class="mb-4">
        <app-stat
          :stat="!employeeStateCountsLoading ? enrollmentPercentage : undefined"
          suffix="%"
          description="of your employees are enrolled"
          :icon="['far', 'user']"
          background-color="core-5"
        />
      </b-col>
      <b-col v-if="!hideStreamsCount" :lg="lg" sm="6" cols="12" class="mb-4">
        <app-stat
          :stat="streams"
          :description="`${bankingInfo.stream.label.toLowerCase()}s made by your employees since launch`"
          :icon="['far', 'exchange']"
          background-color="brand-primary"
        />
      </b-col>
      <b-col :lg="lg" sm="6" cols="12" class="mb-4">
        <app-stat
          :stat="appVisits"
          description="app visits by your employees this month"
          :icon="['far', 'mobile-alt']"
          background-color="warning-lighten-30"
        />
      </b-col>
      <b-col :lg="lg" sm="6" cols="12" class="mb-4">
        <app-stat
          :stat="savings"
          :description="`employees with a ${bankingInfo.save.potLabel}`"
          :icon="['far', 'coins']"
          background-color="core-3"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, ref } from 'vue';
import ApiClient from '@/ApiClient';
import { getBankingInfo } from '@/Banking';
import AppStat from '@/components/AppStat.vue';
import useEmployees from '@/state/composables/useEmployees';
import useDivisionSelection from '@/state/composables/useDivisionSelection';
import State from '@/state/State';

const { divisionId } = useDivisionSelection();
const { enrollmentPercentage, employeeStateCountsLoading } = useEmployees();

const bankingInfo = getBankingInfo();

const appVisits = ref(undefined);
const streams = ref(undefined);
const savings = ref(undefined);

const userIsAdmin = computed(() => State?.state?.claims?.a);

const hideStreamsCount = State?.state?.company?.properties?.portal?.hide_streams_count;
const lg = hideStreamsCount ? 4 : 3;

onBeforeMount(async () => {
  await Promise.all([
    (async () =>
      (appVisits.value = userIsAdmin.value ? (await ApiClient.getAppVisits())?.[0]?.app_visits || 0 : 'NA'))(),
    (async () =>
      (savings.value =
        ((await ApiClient.getBuildMetrics('w2w', divisionId.value || null)) || [])?.results?.find(
          result => result.id === 'pots_opened',
        )?.calculated?.last || 0))(),
    (async () => {
      if (!hideStreamsCount) {
        streams.value = (await ApiClient.getTransfersCount())?.count || 0;
      }
    })(),
  ]);
});
</script>
