<template>
  <Modal title="Make a payment" modal-id="pay-one-person-modal" size="xl" ref="modalRef" @on-close="onClose">
    <div class="content">
      <div class="input-row">
        <div class="input-column">
          <div class="input-title">Employee (search by code or name)</div>
          <div>
            <b-form-input
              type="search"
              placeholder="Search employees by employee code or name if enrolled"
              v-model="searchText"
              @input="debouncedSearch"
              @focus="dropdownVisible = true"
              @blur="onBlur"
              ref="searchInput"
              id="employee-search"
            >
            </b-form-input>
            <div v-if="dropdownVisible" class="dropdown-wrapper">
              <div class="fixed-child">
                <a
                  v-for="(employee, index) in employees"
                  :key="index"
                  @mousedown.prevent
                  @click="onSelectEmployee(employee)"
                  class="dropdown-item"
                >
                  {{ employee.employee_code }} - {{ employee.full_name }}
                </a>
              </div>
            </div>
          </div>
          <SmeAlert v-if="showWarning" level="danger" id="employee-not-found-warning">
            This employee is not enrolled. Please provide the employee payroll code, email and bank details to process
            the payment.</SmeAlert
          >
        </div>
      </div>
      <div class="multi-input-row">
        <div class="input-column">
          <div class="input-title">Employee code</div>
          <b-form-input
            id="employee-code"
            v-model="employeeCode"
            type="text"
            :placeholder="selectedEmployeeDetails?.employee_code || 'Enter employee code'"
            :disabled="!!selectedEmployeeDetails?.employee_code"
          />
        </div>
        <div class="input-column">
          <div class="input-title">Employee email</div>
          <b-form-input
            id="employee-email"
            v-model="email"
            type="text"
            :placeholder="selectedEmployeeDetails?.work_email || 'Enter email'"
            :state="isEmailAddressValid(email)"
          />
        </div>
      </div>
      <div class="multi-input-row">
        <div class="input-column">
          <div class="input-title">Account number</div>
          <b-form-input
            id="account-number"
            v-model="accountNumber"
            type="text"
            :placeholder="selectedEmployeeBankDetails?.accountNumber || 'Enter account number'"
          />
        </div>
        <div class="input-column">
          <div class="input-title">Routing number</div>
          <b-form-input
            id="routing-number"
            v-model="routingNumber"
            type="text"
            :placeholder="selectedEmployeeBankDetails?.routingNumber || 'Enter routing number'"
          />
        </div>
      </div>
      <div class="bank-details-default" v-if="!selectedEmployeeDetails">
        You only need to provide bank details if your employee is not enrolled or if they have a specific account they
        would like to use. If left blank, we will use the bank details aready connect to their account.
      </div>
      <div class="bank-details-danger" v-else-if="!selectedEmployeeBankDetails && isEnrolledOrActive">
        {{ selectedEmployeeDetails.full_name }} is enrolled but has no bank details. Please supply bank details to make
        a payment.
      </div>
      <div class="bank-details-danger" v-else-if="!selectedEmployeeBankDetails && !isEnrolledOrActive">
        {{ selectedEmployeeDetails.full_name }} is not enrolled and has no bank details. Please supply bank details to
        make a payment.
      </div>
      <div class="bank-details-success" v-else-if="selectedEmployeeBankDetails">
        {{ selectedEmployeeDetails.full_name }} has bank details on file. Add new bank details if you would like to use
        a different account.
      </div>
      <div class="multi-input-row">
        <div class="input-column">
          <div class="input-title">Reason</div>
          <b-form-select id="reason" v-model="reason" :options="paymentReasons">
            <template #first v-if="!reason">
              <option value="" disabled>Select a reason</option>
            </template>
          </b-form-select>
        </div>
        <div class="input-column">
          <div class="input-title">Amount to pay</div>
          <b-input-group prepend="$">
            <b-form-input id="amount" v-model="amount" type="number" placeholder="Enter amount" />
          </b-input-group>
        </div>
      </div>
      <div class="multi-input-row" v-if="delayedOffCyclePayments">
        <div class="input-column">
          <div class="input-title">Pay on</div>
          <b-form-datepicker id="pay-on" v-model="deferredDatetime" placeholder="Today" :min="startOfToday()">
          </b-form-datepicker>
        </div>
        <div class="input-column">
          <div class="input-title">District manager email</div>
          <b-form-input
            id="manager-email"
            v-model="managerEmail"
            type="text"
            placeholder="Enter manager email address"
            :state="isEmailAddressValid(managerEmail)"
          />
        </div>
      </div>
    </div>
    <template #modal-footer>
      <div class="custom-footer">
        <div class="confirm-payment-container">
          <b-form-checkbox
            data-testid="confirm-checkbox"
            id="checkbox-1"
            v-model="paymentConfirmed"
            name="checkbox-1"
            class="checkbox"
          >
            I confirm that these details are correct and I would like to proceed with the payment. Once submitted, I
            acknowledge that this payment cannot be reversed as the employee will be notified on payment success.
          </b-form-checkbox>
        </div>
        <div class="footer-cont">
          <div class="error" v-if="errorMessage">{{ errorMessage }}</div>
          <div class="footer">
            <b-button variant="outline-primary" @click="onClose">Cancel</b-button>
            <b-button
              id="submit"
              :loading="false"
              @click="onMakePayment()"
              variant="primary"
              :disabled="!canMakePayment"
              >Make payment {{ deferredDatetime ? 'later' : 'now' }}</b-button
            >
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import Modal from '@/components/Modal.vue';
import ApiClient from '@/ApiClient';
import State from '@/state/State';
import { isEmailAddressValid } from '@/pages/one-time-payments/utils';
import { useMutation, useQuery } from '@tanstack/vue-query';
import { getOffCycleConfig, postSingleOffCyclePayment } from '@/queries/one-time-payments';
import SmeAlert from '@/components/atoms/SmeAlert.vue';
import { startOfToday } from 'date-fns';
import useFeatureFlags from '@/composables/useFeatureFlags';

const modalRef = ref(null);
const searchText = ref('');
const searchInput = ref(null);
const employees = ref([]);
const dropdownVisible = ref(false);
const selectedEmployeeDetails = ref(null);

const employeeId = ref('');
const amount = ref('');
const reason = ref('');
const routingNumber = ref('');
const accountNumber = ref('');
const email = ref('');
const employeeCode = ref('');
const managerEmail = ref('');

const errorMessage = ref('');
const hasStartedSearch = ref(false);
const showWarning = ref(false);
const paymentConfirmed = ref(false);
const paymentReasons = ref([]);

const deferredDatetime = ref(null);

const { delayedOffCyclePayments } = useFeatureFlags();

const makePaymentQuery = useMutation(postSingleOffCyclePayment());
const offCycleConfig = useQuery(getOffCycleConfig());

watch([offCycleConfig.fetchStatus, offCycleConfig.isPending], () => {
  if (offCycleConfig.data.value) {
    paymentReasons.value = offCycleConfig.data.value.supported_payment_types.map(item => ({
      value: item.name,
      text: item.display_value,
    }));
  }
});

const canMakePayment = computed(() => {
  if (!selectedEmployeeBankDetails.value) {
    return (
      paymentConfirmed.value &&
      !!employeeCode.value &&
      !!amount.value &&
      !!reason.value &&
      !!routingNumber.value &&
      !!accountNumber.value
    );
  }

  return paymentConfirmed.value && !!employeeCode.value && !!amount.value && !!reason.value;
});

let debounceTimeout;
const customDebounce = (func, delay) => {
  return (...args) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }
    debounceTimeout = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const debouncedSearch = customDebounce(() => {
  onSearchEmployees();
  onUpdate();
}, 200);

const onSearchEmployees = async () => {
  if (searchText.value.length < 3) {
    employees.value = [];
    employeeCode.value = '';
    email.value = '';
    selectedEmployeeDetails.value = null;
    return;
  }
  hasStartedSearch.value = true;

  const params = {
    search_text: searchText.value,
    company_id: State.state.company.company_id,
    offset: 0,
    limit: 10,
  };

  const data = await ApiClient.getPaginatedEmployees(params);
  employees.value = data.data;
};

const onSelectEmployee = employee => {
  searchText.value = employee.full_name;
  employeeId.value = employee.employee_id;
  selectedEmployeeDetails.value = employee;
  email.value = employee.work_email;
  employeeCode.value = employee.employee_code;

  employees.value = [];
  dropdownVisible.value = false;
  showWarning.value = false;
};

const onUpdate = () => {
  if (searchText.value.length < 3) {
    employees.value = [];
    employeeCode.value = '';
    email.value = '';
    selectedEmployeeDetails.value = null;
    showWarning.value = false;
  } else {
    showWarning.value = hasStartedSearch.value && employees.value?.length === 0 && !employeeCode.value;
    dropdownVisible.value = true;
  }
};

const onBlur = () => {
  dropdownVisible.value = false;
};

const isEnrolledOrActive = computed(() => {
  return ['ACTIVE', 'ENROLLED'].includes(selectedEmployeeDetails.value?.current_state);
});

const selectedEmployeeBankDetails = computed(() => {
  if (
    !selectedEmployeeDetails.value?.properties?.beneficiary_account_number ||
    !selectedEmployeeDetails.value?.properties?.beneficiary_sort_code
  ) {
    return null;
  }
  return {
    accountNumber: selectedEmployeeDetails?.value?.properties?.beneficiary_account_number,
    routingNumber: selectedEmployeeDetails?.value?.properties?.beneficiary_sort_code,
  };
});

const onMakePayment = async () => {
  const payload = {
    payments: [
      {
        employee_code: employeeCode.value,
        employee_email: email.value,
        amount: amount.value * 100,
        payment_type: reason.value.toUpperCase(),
        routing_number: routingNumber.value,
        account_number: accountNumber.value,
      },
    ],
    billing_type: 'INVOICE', // is this always the case for single person ????
    properties: {
      deferred_payment_datetime: deferredDatetime.value ? `${deferredDatetime.value}T00:00:00Z` : null,
      district_manager_email: managerEmail.value,
    },
  };
  makePaymentQuery.mutate(payload, {
    onSuccess: () => {
      onClose();
    },
    onError: e => {
      errorMessage.value = `An error occurred: ${e}`;
    },
  });
};

const onClose = async () => {
  selectedEmployeeDetails.value = null;
  searchText.value = '';
  amount.value = '';
  reason.value = '';
  routingNumber.value = '';
  accountNumber.value = '';
  email.value = '';
  employeeCode.value = '';
  employeeId.value = '';
  showWarning.value = false;
  paymentConfirmed.value = false;
  managerEmail.value = '';
  deferredDatetime.value = null;

  modalRef.value.modal.hide();
};
</script>

<style scoped>
.employee-dropdown {
  max-height: 200px;
  overflow-y: auto;
  list-style-type: none;
  position: absolute;
  width: 90%;
  background-color: var(--palette-color-default-lighten-90);
  border-radius: 0.25rem;
  top: 2.5rem;
}

.dropdown-item {
  padding: 0.5rem 1rem;
  font-size: 12px;
  text-wrap: wrap;

  &:hover {
    background-color: var(--palette-color-default-lighten-90);
    cursor: pointer;
  }
}

.employee-search {
  width: -webkit-fill-available;
}

.content {
  padding: 0.5rem 2rem;
}

.input-row {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.multi-input-row {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  margin-top: 1rem;
}

.input-column {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: -webkit-fill-available;
}

.dropdown-wrapper {
  position: absolute;
  background-color: var(--palette-color-base-white);
  border: 1px solid var(--palette-color-default-lighten-90);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  z-index: 1000;
  width: 85%;
}

.dropdown-item:last-child {
  border-bottom: none;
}

.footer {
  display: flex;
  gap: 1rem;
}

.bank-details-default {
  font-size: 12px;
  margin: 0.5rem 0.5rem 0.5rem 0;
}

.bank-details-danger {
  font-size: 12px;
  color: var(--palette-color-danger-darken-10);
  margin: 0.5rem 0.5rem 0.5rem 0;
}

.bank-details-success {
  font-size: 12px;
  color: var(--palette-color-success-darken-10);
  margin: 0.5rem 0.5rem 0.5rem 0;
}

.error {
  color: var(--palette-color-danger-darken-10);
  background-color: var(--palette-color-danger-lighten-90);
  font-size: 12px;
  padding: 0.5rem;
  border-radius: 0.25rem;
  display: flex;
  align-self: center;
  width: 100%;
}

.custom-footer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-end;
}

.confirm-payment-container {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.footer-cont {
  width: 100%;
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
