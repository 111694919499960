<template>
  <app-page title="Bank Payments" subtitle="View your historic bank payment runs" icon="calendar-days">
    <template v-slot:custom-content>
      <b-container fluid class="payment-run-wrapper" ref="stepsWrapper">
        <b-row class="mt-4">
          <b-col cols="12">
            <sme-card title="Your bank payment history" class="bank-payment-runs" large>
              <app-loading :loading="bankPaymentHistoryLoading" />
              <template v-if="!bankPaymentHistoryLoading && bankPaymentRunHistory">
                <b-table
                  ref="table"
                  :items="bankPaymentRunHistory"
                  :fields="BANK_PAYMENT_HISTORY_FIELDS"
                  :hover="$attrs.hover !== undefined ? $attrs.hover : true"
                  responsive="sm"
                  borderless
                  show-empty
                  v-bind="$attrs"
                  v-on="$listeners"
                  @row-clicked="rowClickHandler"
                  :tbody-tr-class="rowClass"
                  class="payment-items"
                >
                  <template #cell(pay_day)="row">
                    <div class="payment-cell pay-day-cell">
                      <span>
                        Paid on<br />
                        <strong class="h3 text-primary">{{ formatDate(row.item.pay_day) }}</strong>
                      </span>
                      <small class="hide-mobile text-muted">
                        Period:
                        <span
                          >{{ formatDate(row.item.pay_period_start) }} - {{ formatDate(row.item.pay_period_end) }}</span
                        >
                      </small>
                    </div>
                  </template>

                  <template #cell(total_amount)="row">
                    <div class="payment-cell">
                      <span>
                        Total amount<br />
                        <strong class="h3 text-primary">{{ formatAmount(row.item.total_amount) }}</strong>
                      </span>
                    </div>
                  </template>

                  <template #cell(total_employees)="row">
                    <div class="payment-cell">
                      <span>
                        Employees paid<br />
                        <strong class="h3 text-primary">{{ row.item.employee_payments.length }}</strong>
                      </span>
                    </div>
                  </template>

                  <template #cell(view_details)>
                    <div class="payment-cell arrow"></div>
                  </template>

                  <template #row-details="row">
                    <div class="employee-wrapper">
                      <div class="employee-wrapper-upper-buttons">
                        <export-button
                          file-name="bank_pay_report"
                          :export-data="getEmployeeList(row.item)"
                          :export-file-rows="exportFileRows"
                          :export-headers="exportHeaders()"
                        />
                        <div class="contact-support">
                          <small>Notice anything wrong with this bank pay run?</small>
                          <b-button type="button" variant="light" class="ml-3" size="sm" @click="contactSupport">
                            Contact support
                          </b-button>
                        </div>
                      </div>

                      <div class="employee-table-wrapper">
                        <b-table
                          ref="table-employees"
                          :items="getEmployeeList(row.item)"
                          :fields="EMPLOYEE_LIST_FIELDS"
                          responsive="sm"
                          :hover="undefined"
                          borderless
                          show-empty
                          v-bind="$attrs"
                          v-on="$listeners"
                          class="employee-table"
                        >
                          <template #cell(employee_code)="data">
                            <span>
                              {{ data.item.employee_code }}
                            </span>
                          </template>

                          <template #cell(full_name)="data">
                            <router-link :to="{ name: 'employee', params: { employeeId: data.item.employee_id } }">
                              {{ data.item.full_name }}
                            </router-link>
                          </template>

                          <template #cell(net_amount_pence)="data">
                            <span>
                              {{ formatAmount(data.item.net_amount_pence) }}
                            </span>
                          </template>

                          <template #cell(shift_count)="data">
                            <span>
                              {{ data.item.shift_count }}
                            </span>
                          </template>
                        </b-table>
                      </div>
                    </div>
                  </template>
                </b-table>
              </template>
            </sme-card>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </app-page>
</template>

<script setup>
import * as moment from 'moment/moment';
import { ref, onMounted } from 'vue';
import ApiClient from '@/ApiClient';
import AppLoading from '@/components/AppLoading';
import AppPage from '@/components/AppPage';
import ExportButton from '@/components/ExportButton.vue';
import SmeCard from '@/components/atoms/SmeCard.vue';
import State from '@/state/State';
import { toCurrency } from '@/utils';
import { contactSupport } from '@/utils/support';

const state = ref(State.state);
const bankPaymentRunHistory = ref(null);
const bankPaymentHistoryLoading = ref(false);

const BANK_PAYMENT_HISTORY_FIELDS = [
  {
    key: 'pay_day',
    label: 'Payment Day',
    thClass: 'd-none',
    thStyle: { display: 'none' },
  },
  {
    key: 'total_amount',
    label: 'Total amount',
    thClass: 'd-none',
    thStyle: { display: 'none' },
  },
  {
    key: 'total_employees',
    label: 'Total employees',
    thClass: 'd-none',
    thStyle: { display: 'none' },
  },
  {
    key: 'view_details',
    label: 'View Details',
    thClass: 'd-none',
    thStyle: { display: 'none' },
  },
];

const EMPLOYEE_LIST_FIELDS = [
  {
    key: 'employee_code',
    label: 'Employee Code',
  },
  {
    key: 'full_name',
    label: 'Name',
  },
  {
    key: 'shift_count',
    label: 'Total shifts',
  },
  {
    key: 'net_amount_pence',
    label: 'Net pay',
    formatter: value => toCurrency(value),
  },
];

const activeBankPayRunRow = ref({
  payment_day: null,
  index: null,
  open: false,
});

onMounted(async () => {
  getBankPaymentRunHistory();
});

const getEmployeeList = rowItem => {
  const employeeList = [];

  if (rowItem.employee_payments.length > 0) {
    return rowItem.employee_payments;
  }

  return employeeList;
};

const getBankPaymentRunHistory = async () => {
  // History currently just for WEEKLY bank pay runs, not daily

  bankPaymentHistoryLoading.value = true;
  try {
    const history = await ApiClient.getBankPayHistory(state.value.company.company_id);
    bankPaymentRunHistory.value = history;
  } catch (error) {
    console.error('Could not get payment run history', error.message);
  } finally {
    bankPaymentHistoryLoading.value = false;
  }
};

const formatAmount = amount => {
  return toCurrency(amount, state.value.company.default_currency);
};

const formatDate = date => {
  if (date) {
    return moment(date).format('DD MMMM YYYY');
  }
  return '';
};

const rowClickHandler = (item, index) => {
  item._showDetails = !item._showDetails;
  const { pay_day } = item;
  if (!pay_day) {
    return;
  }

  activeBankPayRunRow.value = {
    pay_day,
    index,
    open: !activeBankPayRunRow.value.open,
  };
};

const rowClass = item => {
  const trClasses = ['tr-card'];
  if (!item) {
    return trClasses;
  }
  if (item.pay_day === activeBankPayRunRow.value.pay_day) {
    trClasses.push('selected-row');
  }

  if (item.pay_day === activeBankPayRunRow.value.pay_day && item._showDetails) {
    trClasses.push('open');
  }
  return trClasses;
};

const exportFileRows = item =>
  EMPLOYEE_LIST_FIELDS.filter(field => field.export !== false).map(field =>
    field.formatter ? field.formatter(item[field.key], field.key, item) : item[field.key],
  );

const exportHeaders = () => EMPLOYEE_LIST_FIELDS.filter(field => field.export !== false).map(field => field.label);
</script>

<style scoped lang="scss">
.payment-run-wrapper {
  position: relative;

  .bank-payment-runs {
    position: relative;
  }

  .payment-items {
    margin: 0;

    ::v-deep .tr-card {
      border: 1px solid var(--palette-color-default-lighten-80);
      border-radius: 8px;
      display: flex;
      margin-bottom: 10px;
      justify-content: space-between;
      background: var(--palette-color-base-white);

      &:hover {
        cursor: pointer;
      }

      &.b-table-has-details {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;

        .arrow {
          &::after {
            transform: rotate(45deg) translateY(-50%);
          }
        }

        &:hover + .b-table-details {
          box-shadow: inset 1px 0 0 var(--palette-color-default-lighten-90),
            inset -1px 0 0 var(--palette-color-default-lighten-90), 0 1px 2px 0 var(--palette-color-default-lighten-60),
            0 1px 3px 1px var(--palette-color-default-lighten-80);

          &::before {
            display: block;
            content: '';
            width: calc(100% - 2px);
            height: 6px;
            position: absolute;
            left: 1px;
            top: -2px;
            background-color: var(--palette-color-base-white);
          }
        }
      }

      &.b-table-details {
        position: relative;
        margin-top: -10px;
        border-top: 0;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        & > td {
          display: block;
          width: 100%;
        }

        &:hover {
          cursor: default;

          &::before {
            display: block;
            content: '';
            width: calc(100% - 2px);
            height: 2px;
            position: absolute;
            left: 1px;
            top: -2px;
            background-color: var(--palette-color-base-white);
          }
        }

        .employee-wrapper {
          border-top: solid 1px var(--palette-color-default-lighten-80);
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;

          .employee-wrapper-upper-buttons {
            display: flex;
            justify-content: space-between;
            padding: 1rem;
            border-bottom: solid 1px var(--palette-color-default-lighten-80);
          }

          .employee-table {
            padding-bottom: 1rem;
            border-bottom: solid 1px var(--palette-color-default-lighten-80);

            .last-table-column {
              padding-right: 1rem;
              text-align: right;
            }
          }

          .totals {
            align-self: flex-end;
            padding-right: 1rem;
            font-size: 1rem;
          }

          .contact-support {
            align-self: flex-end;
            padding-right: 1rem;
            margin-top: 0.5rem;
          }
        }
      }

      &.selected-row {
        position: relative;
        box-shadow: 0px 0px 0px 5px var(--palette-color-brand-primary);

        &.b-table-has-details:hover + .b-table-details {
          box-shadow: 0px 0px 0px 5px var(--palette-color-brand-primary);

          &:before {
            display: block;
            content: '';
            width: calc(100% + 1px);
            height: 6px;
            position: absolute;
            left: -0.6px;
            top: -1px;
            background: var(--palette-color-base-white);
            z-index: 1;
          }
        }

        &.b-table-has-details + .b-table-details {
          &:before {
            display: block;
            content: '';
            width: calc(100% + 1px);
            height: 6px;
            position: absolute;
            left: -0.6px;
            top: -5px;
            background: var(--palette-color-base-white);
            z-index: 1;
          }
        }

        .b-table-details:hover {
          &:before {
            display: block;
            content: '';
            width: calc(100% + 1px);
            height: 6px;
            position: absolute;
            left: -0.6px;
            top: -5px;
            background: var(--palette-color-base-white);
            z-index: 1;
          }
        }

        &.open {
          .arrow {
            &::after {
              transform: rotate(45deg) translateY(-50%);
            }
          }

          .hide-mobile {
            display: block;
          }

          .hide-desktop {
            display: none;
          }
        }
      }

      .hide-desktop {
        display: none;

        @media (max-width: 576px) {
          display: block;
        }
      }

      .hide-mobile {
        display: block;

        @media (max-width: 576px) {
          display: none;
        }
      }
    }

    .payment-cell {
      display: flex;
      flex-direction: column;

      &.arrow {
        position: relative;
        height: 100%;

        &::after {
          display: block;
          content: '';
          width: 14px;
          height: 14px;
          position: absolute;
          right: 10px;
          top: 50%;
          border-right: 2px solid var(--palette-color-base-black);
          border-bottom: 2px solid var(--palette-color-base-black);
          transform: rotate(315deg) translateY(-50%);
          transition: all 0.3s ease-in-out;
          transform-origin: top;
        }
      }
    }

    .pay-day-cell {
      min-width: 275px;

      @media (max-width: 576px) {
        min-width: 0;
      }
    }

    ::v-deep .table-hover {
      .employee-table {
        tr:hover {
          box-shadow: none;
        }
      }
    }
  }
}

.account__balance-caption {
  margin-top: 12px;
  padding-left: 20px;
  text-align: left;
  font-size: 20px;
  @media (max-width: 768px) {
    text-align: left;
  }
}
</style>
